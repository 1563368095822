import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { AgGridModule } from 'ag-grid-angular';
import { DpDatePickerModule } from 'ng2-date-picker';
import { MapboxComponent } from '../components/mapbox/mapbox.component';
import { FacilityActionColumnComponent } from '../components/ag-grid-columns/facility-action-column/facility-action-column.component';
import { FacilityTypeColumnSelectionEditorComponent } from '../components/facility-type-column-selection-editor/facility-type-column-selection-editor.component';
import { FacilityUploadErrorCompletedComponent } from '../components/facility-upload-error-completed/facility-upload-error-completed.component';
import { StateSelectComponent } from '../components/state-select/state-select.component';

import { MaterialModule } from './material.module';
import { GridDatepickerEditorComponent } from '../components/grid-datepicker-editor/grid-datepicker-editor.component';
import { UploadErrorCompletedComponent } from '../components/upload-error-completed/upload-error-completed.component';
import { ChartBarVerticalComponent } from '../components/chart-bar-vertical/chart-bar-vertical.component';
import { ChartMapComponent } from '../components/chart-map/chart-map.component';
import { ChartPieComponent } from '../components/chart-pie/chart-pie.component';
import { ChartHorizontalBarProgressComponent } from '../components/chart-horizontal-bar-progress/chart-horizontal-bar-progress.component';
import { ChartBarStackedComponent } from '../components/chart-bar-stacked/chart-bar-stacked.component';
import { SignInOrRegisterModalComponent } from '../components/sign-in-or-register-modal/sign-in-or-register-modal.component';
import { ChartLineComponent } from '../components/chart-line/chart-line.component';
import { UiModule } from '@iconic-air-monorepo/ui';
import { SendEmailReportDialogComponent } from '../components/send-email-report-dialog/send-email-report-dialog.component';
import { TrueFalseColumnComponent } from '../components/ag-grid-columns/true-false-column/true-false-column.component';
import { PhotoPreviewModalComponent } from '../components/photo-preview-dialog/photo-preview.component';
import { ChartTitleEditComponent } from './../components/chart-title-edit/chart-title-edit.component';
import { FormulaTooltipComponent } from '../components/formula-tooltip/formula-tooltip.component';
import { FormulaTooltipDirective } from './../components/formula-tooltip/formula-tooltip.directive';
import { DeleteColumnComponent } from '../components/ag-grid-columns/delete-column/delete-column.component';
import { ChartTreemapComponent } from '../components/chart-treemap/chart-treemap.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { UploadFileDialogComponent } from '../components/upload-file/upload-file-dialog/upload-file-dialog.component';
import { UploadFileComponent } from '../components/upload-file/upload-file.component';
import { BulkUploadModule } from '../components/bulk-upload/bulk-upload.module';
import { PipesModule } from '../pipes/pipes.module';
import { YesNoColumnComponent } from '../components/ag-grid-columns/yes-no-column/yes-no-column.component';
import { TrackFocusDirective } from '../directives/track-focus.directive';
import { GasSampleComponent } from '../components/gas-sample/gas-sample.component';
import { FacilitySelectComponent } from '../components/facility-select/facility-select.component';
import { ReportingFacilitySelectComponent } from '../components/reporting-facility-select/reporting-facility-select.component';
import { LocationGasSamplesComponent } from '../pages/locations/location-details/location-gas-samples/location-gas-samples.component';
import { ProgressStatusDialogComponent } from '../components/progress-status-dialog/progress-status-dialog.component';
import { BlankCellRendererComponent } from '../components/ag-grid-columns/blank-cell-renderer/blank-cell-renderer.component';
import { BetaIndicatorComponent } from '../components/beta-indicator/beta-indicator.component';
import { BusinessAreaSelectComponent } from '../components/business-area-select/business-area-select.component';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DpDatePickerModule,
    UiModule,
    AgGridModule.withComponents([]),
    DpDatePickerModule,
    NgApexchartsModule,
    BulkUploadModule,
    PipesModule,
  ],
  declarations: [
    GasSampleComponent,
    MapboxComponent,
    FacilityUploadErrorCompletedComponent,
    FacilityTypeColumnSelectionEditorComponent,
    FacilityActionColumnComponent,
    GridDatepickerEditorComponent,
    UploadErrorCompletedComponent,
    ChartBarVerticalComponent,
    ChartBarStackedComponent,
    ChartHorizontalBarProgressComponent,
    ChartMapComponent,
    ChartPieComponent,
    ChartLineComponent,
    SignInOrRegisterModalComponent,
    StateSelectComponent,
    SendEmailReportDialogComponent,
    ChartTitleEditComponent,
    TrueFalseColumnComponent,
    PhotoPreviewModalComponent,
    DeleteColumnComponent,
    ChartTreemapComponent,
    UploadFileDialogComponent,
    UploadFileComponent,
    FormulaTooltipComponent,
    FormulaTooltipDirective,
    YesNoColumnComponent,
    TrackFocusDirective,
    FacilitySelectComponent,
    ReportingFacilitySelectComponent,
    LocationGasSamplesComponent,
    ProgressStatusDialogComponent,
    BlankCellRendererComponent,
    BetaIndicatorComponent,
    BusinessAreaSelectComponent,
  ],
  exports: [
    GasSampleComponent,
    TrackFocusDirective,
    MapboxComponent,
    SendEmailReportDialogComponent,
    StateSelectComponent,
    FacilityUploadErrorCompletedComponent,
    FacilityTypeColumnSelectionEditorComponent,
    FacilityActionColumnComponent,
    GridDatepickerEditorComponent,
    UploadErrorCompletedComponent,
    ChartBarVerticalComponent,
    ChartMapComponent,
    ChartPieComponent,
    ChartBarStackedComponent,
    ChartHorizontalBarProgressComponent,
    ChartLineComponent,
    SendEmailReportDialogComponent,
    ChartTitleEditComponent,
    DpDatePickerModule,
    ChartTreemapComponent,
    UploadFileDialogComponent,
    UploadFileComponent,
    FormulaTooltipComponent,
    FormulaTooltipDirective,
    ReportingFacilitySelectComponent,
    FacilitySelectComponent,
    LocationGasSamplesComponent,
    ProgressStatusDialogComponent,
    BlankCellRendererComponent,
    BetaIndicatorComponent,
    BusinessAreaSelectComponent,
  ],
})
export class ThemeModule {}
