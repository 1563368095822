import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Facility, GasSample } from '@iconic-air-monorepo/models';
import { GasSampleComponent } from '../../../../components/gas-sample/gas-sample.component';
import * as moment from 'moment';
import { PermissionsWebGuard } from '../../../../guards/permissions/permissions-web-guard.guard';

@Component({
  selector: 'app-location-gas-samples',
  templateUrl: './location-gas-samples.component.html',
  styleUrls: ['./location-gas-samples.component.scss'],
})
export class LocationGasSamplesComponent {
  isAdmin = false;
  gasSampleColumns: string[] = [
    'name',
    'sampleDate',
    'effectiveDate',
    'co2',
    'ch4',
    'edit',
  ];
  @Input() facility: Facility;
  loading = false;
  constructor(
    private perms: PermissionsWebGuard,
    private afs: AngularFirestore,
    private dialog: MatDialog,
  ) {
    this.isAdmin = this.perms.userData.isAdmin;
  }

  addGasSample(
    event,
    isNewSample: boolean,
    index?: number,
    sample?: GasSample,
  ) {
    event.stopPropagation();
    const data = sample ? sample : null;

    const gasSampleDialogRef = this.dialog.open(GasSampleComponent, {
      data: {
        gasSample: data,
        isNewSample: isNewSample,
      },
    });

    gasSampleDialogRef.afterClosed().subscribe(async (data: any) => {
      if (!data) return;
      // not every facility has a gasSample so initialize the empty array here.
      if (!this.facility.gasSamples) {
        this.facility.gasSamples = [];
      }

      data.gasSample.sampleDate =
        moment(data.gasSample.sampleDate).unix() * 1000;
      data.gasSample.effectiveDate =
        moment(data.gasSample.effectiveDate).unix() * 1000;
      if (isNewSample) {
        this.facility.gasSamples.push(data.gasSample);
      } else this.facility.gasSamples[index] = data.gasSample;

      this.loading = true;
      try {
        await this.afs
          .collection('customers')
          .doc(this.perms.activeCustomerId)
          .collection('facilities')
          .doc(this.facility.facilityId)
          .update({ gasSamples: this.facility.gasSamples });
      } finally {
        this.loading = false;
      }
    });
  }
}
