import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  SheetValidationError,
  BulkUploadColumn,
  SubBasin,
} from '@iconic-air/models';
import { PermissionsWebGuard } from '../../../guards/permissions/permissions-web-guard.guard';
import { StaticDataService } from '../../../services/static-data/static.data.service';
import * as XLSX from 'xlsx';
import { validateTemplate } from './validate-template';
import { lastValueFrom } from 'rxjs';
import {
  checkDoubleSpaces,
  checkEmail,
  checkLatitude,
  checkLongitude,
  checkPhoneNumber,
  checkYear,
  checkDate,
  checkBoolean,
  checkApiNumber,
  checkApi14Number,
  checkReportingPeriod,
} from './validate-data-type';
import { CustomerDataService } from '../../../services/customer-data/customer-data.service';
import { ReportingFacilityDatabaseService } from '../../../services-database/reporting-facilities/reporting-facility-database.service';
import { HttpClient } from '@angular/common/http';
import { kebabCase } from 'lodash';

@Component({
  selector: 'app-bulk-upload-file-validation-dialog',
  templateUrl: './file-validation-dialog.component.html',
  styleUrls: ['./file-validation-dialog.component.scss'],
})
export class FileValidationDialogComponent implements OnInit {
  file: File;
  fileLoading = true;
  errorLog: SheetValidationError[] = [];
  columns: BulkUploadColumn[];
  collection: string;
  collectionData: any;
  secondaryKey: string;
  secondaryValue: string;
  #api = false;
  #apiPaginate = false;
  #apiPaginateCount = 1000;
  #apiGetRoute = '';
  private _valueToIncludeInPath: string;
  #reportingYear: string;
  #yearInPath: boolean;
  collectionGrabbed = false;
  customerCollection: any = {};
  massEdit: boolean;

  isNumber(value): boolean {
    return !Number.isNaN(Number(value));
  }

  constructor(
    private dialogRef: MatDialogRef<FileValidationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private staticService: StaticDataService,
    private afs: AngularFirestore,
    private perms: PermissionsWebGuard,
    private customerData: CustomerDataService,
    private _http: HttpClient,
    private _tableReportingFacilities: ReportingFacilityDatabaseService,
  ) {
    this.file = data.file;
    this.columns = JSON.parse(JSON.stringify(data.columns));
    this.collection = data.collection;
    this.massEdit = data.massEdit;
    this.secondaryKey = data.secondaryKey;
    this.secondaryValue = data.secondaryValue;
    this.#api = data.api;
    this.#apiPaginate = data.apiPaginate;
    this.#apiPaginateCount = data.apiPaginateCount
      ? data.apiPaginateCount
      : 1000;
    this.#apiGetRoute = data.apiGetRoute;
    this._valueToIncludeInPath = data.valueToIncludeInPath;
    this.#reportingYear = data.reportingYear;
    this.#yearInPath = data.yearInPath;
    if (this.massEdit && !this.columns?.find((column) => column.key === 'id')) {
      (this.columns as any).push({
        key: 'id',
        description: 'id',
        required: true,
        mustExist: true,
        uniqueInSheet: true,
      });
    }
  }

  ngOnInit(): void {
    this.processData();
  }

  processData = async () => {
    try {
      const wb: XLSX.WorkBook = await this.loadSpreadsheet(this.file);

      // Validate Template
      const newErrors = validateTemplate(
        wb,
        this.columns.map((column) =>
          column.description ? column.description : column.key,
        ),
        this.columns
          .filter((column) => column.required)
          .map((column) =>
            column.description ? column.description : column.key,
          ),
      );
      if (newErrors !== null && newErrors.length > 0) {
        newErrors.forEach((i) => this.errorLog.push(i));
      }

      // Validate Data Input
      const newDataErrors = await this.validateData(
        wb.Sheets[wb.SheetNames[0]],
        wb.SheetNames[0],
      );
      if (newDataErrors !== null && newDataErrors.length > 0) {
        newDataErrors.forEach((i) => this.errorLog.push(i));
      }

      this.dialogRef.close(this.errorLog);
    } catch (error) {
      throw error.message;
    }
  };

  loadSpreadsheet(file: File): Promise<XLSX.WorkBook> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      this.fileLoading = true;

      reader.readAsArrayBuffer(file);
      this.fileLoading = true;

      reader.onload = (e: any) => {
        const binarystr = new Uint8Array(e.target.result);
        const wb: XLSX.WorkBook = XLSX.read(binarystr, {
          type: 'array',
          raw: true,
          cellFormula: false,
        });
        return resolve(wb);
      };
      reader.onerror = (e: any) => {
        return reject(new Error('Error loading file...'));
      };
    });
  }

  closeModal() {
    this.dialogRef.close(undefined);
  }

  private checkState(state: string | number | boolean) {
    if (!state) return true;
    const abreviations = this.staticService.states.map((currentState) =>
      currentState.abbreviation?.toLowerCase(),
    );
    if (state && abreviations.indexOf(state?.toString()) > -1) return true;
    return false;
  }

  private checkSubBasin(subBasin: string) {
    if (!subBasin) return true;
    return this.customerData?.subBasins
      ?.map((subBasin: SubBasin) => subBasin?.subBasinFullName?.toLowerCase())
      ?.indexOf(subBasin?.toString()?.toLowerCase());
  }

  private addError(
    sheetName: string,
    currentColumnData: {
      labelCell: string;
      label: string;
      currentValueCell: string;
      currentValue: string | number | boolean;
    },
    errorMessage: string,
    errorArray,
  ) {
    errorArray.push({
      sheetName,
      sheetIndex: 0,
      labelCell: currentColumnData.labelCell,
      label: currentColumnData.label,
      currentValueCell: currentColumnData.currentValueCell,
      currentValue: currentColumnData.currentValue,
      expectedValue: errorMessage,
    });
  }

  async validateData(
    sheet: XLSX.WorkSheet,
    sheetName: string,
  ): Promise<null | SheetValidationError[]> {
    // we only need to validate data in one sheet since only one can have data
    let newErrors: SheetValidationError[] = [];
    const maxRow = Number(sheet['!ref']?.split(':')[1].replace(/\D/g, ''));
    // get the columns that have headers so we can loop through them
    const columnsToLoop = Object.keys(sheet)
      .filter(
        (value) =>
          value
            .substring(0, value.length - 1)
            .replace(new RegExp('[0-9]', 'g'), '') +
            '1' ===
          value,
      )
      .map((value) => value.replace(new RegExp('[0-9]', 'g'), ''));
    // store rows we are adding that are dubbed unique so
    // it doesn't just check the db but that we are not uploading duplicated
    const uniqueCheck: { [key: string]: string[] } = {};
    for (let i = 2; i <= maxRow; i++) {
      let rowEmpty = true;
      const currentData: {
        [key: string]: {
          labelCell: string;
          label: string;
          currentValueCell: string;
          currentValue: string | number | boolean;
        };
      } = {};
      const currentRowErrors: SheetValidationError[] = [];
      for (let h = 0; h < columnsToLoop.length; h++) {
        const column = columnsToLoop[h];
        const currentValueCell = column + i.toString();
        let currentValue = sheet[currentValueCell]?.v;
        if (typeof currentValue === 'string')
          currentValue = currentValue?.toLowerCase();
        const labelCell = `${column}1`;
        const label = sheet[labelCell]?.v;
        if (label == 'legend') continue;
        if (currentValue || currentValue === 0) rowEmpty = false;
        currentData[label] = {
          labelCell,
          label,
          currentValueCell,
          currentValue,
        };
      }
      if (rowEmpty) continue;
      const keys = Object.keys(currentData);
      for (let h = 0; h < keys.length; h++) {
        const currentKey = keys[h];
        const currentColumnData = currentData[currentKey];
        const foundColumn = this.columns.find((currentColumn) => {
          return (
            (currentColumn.description
              ? currentColumn.description
              : currentColumn.key) === currentKey
          );
        });
        // No Double Spaces
        if (!checkDoubleSpaces(currentColumnData.currentValue)) {
          this.addError(
            sheetName,
            currentColumnData,
            'However, two or more consecutive spaces are not allowed.',
            currentRowErrors,
          );
        }

        if (foundColumn?.required) {
          let errored = true;

          if (
            foundColumn?.validator?.key &&
            foundColumn?.validator?.values?.length
          ) {
            const requiredKeyValues = foundColumn.validator.values.map(
              (value) => {
                let returnValue = value;
                if (
                  typeof returnValue === 'string' &&
                  returnValue?.toLowerCase()
                )
                  returnValue = returnValue?.toLowerCase();
                return returnValue;
              },
            );

            const test =
              currentData[foundColumn.validator.key].currentValue ||
              currentData[foundColumn.validator.key].currentValue === 0 ||
              currentData[foundColumn.validator.key].currentValue === '0'
                ? currentData[
                    foundColumn.validator.key
                  ].currentValue?.toString()
                : 'empty';

            if (foundColumn.validator.includes) {
              for (const value of requiredKeyValues) {
                if (!test.includes(value)) {
                  errored = false;
                  break;
                }
              }
            } else if (!requiredKeyValues.includes(test)) {
              errored = false;
            }

            if (!errored) {
              if (
                currentColumnData.currentValue ||
                currentColumnData.currentValue === 0
              ) {
                this.addError(
                  sheetName,
                  currentColumnData,
                  `${currentKey} is only applicable if ${
                    foundColumn.validator.key
                  } has a value in this list ${requiredKeyValues.toString()}`,
                  currentRowErrors,
                );
              }
            }
          }
          if (
            currentColumnData.currentValue ||
            currentColumnData.currentValue === 0 ||
            currentColumnData.currentValue === false
          )
            errored = false;
          if (errored && !foundColumn?.validator?.key)
            this.addError(
              sheetName,
              currentColumnData,
              'However, is required.',
              currentRowErrors,
            );
          else if (errored && foundColumn?.validator?.key) {
            this.addError(
              sheetName,
              currentColumnData,
              `${currentKey} is required if ${foundColumn.validator.key} is ${
                currentData[foundColumn.validator.key].currentValue
              }, please provide a value`,
              currentRowErrors,
            );
          }
        }

        if (foundColumn?.mustExist) {
          if (!this.collectionGrabbed) {
            let values;

            if (this.#api) {
              const apiUrl = `${this.#apiGetRoute}${
                this.#yearInPath ? `/${this.#reportingYear}` : ''
              }`;

              if (!apiUrl) {
                throw 'Iconic air did not format this url correctly, please reach out to them to fix this.';
              }
              if (this.#apiPaginate) {
                const valuesToAdd: any[] = [];
                let currentPage = 1;
                const firstCallValues: {
                  data: any[];
                  perPage: number;
                  currentPage: number;
                  total?: number;
                } = (await lastValueFrom(
                  this._http.get(
                    apiUrl +
                      '?currentPage=' +
                      currentPage +
                      '&perPage=' +
                      this.#apiPaginateCount +
                      '&includeTotal=true',
                  ),
                )) as {
                  data: any[];
                  perPage: number;
                  currentPage: number;
                  total?: number;
                };

                firstCallValues.data.forEach((item) => {
                  valuesToAdd.push(item);
                });

                const getMoreData = async () => {
                  currentPage++;
                  const getMoreValues = (await lastValueFrom(
                    this._http.get(
                      apiUrl +
                        '?currentPage=' +
                        currentPage +
                        '&perPage=' +
                        this.#apiPaginateCount +
                        '&includeTotal=false',
                    ),
                  )) as {
                    data: any[];
                    perPage: number;
                    currentPage: number;
                    total?: number;
                  };

                  getMoreValues.data.forEach((item) => {
                    valuesToAdd.push(item);
                  });

                  if (firstCallValues.total) {
                    if (valuesToAdd.length < firstCallValues.total) {
                      await getMoreData();
                    }
                  } else {
                    if (getMoreValues.data.length) {
                      await getMoreData();
                    }
                  }

                  return;
                };

                if (firstCallValues.total) {
                  if (valuesToAdd.length < firstCallValues.total) {
                    await getMoreData();
                  }
                } else {
                  if (firstCallValues.data.length) {
                    await getMoreData();
                  }
                }

                values = {
                  docs: valuesToAdd,
                };
              } else {
                const valuesToAdd = await lastValueFrom(this._http.get(apiUrl));

                values = {
                  docs: valuesToAdd,
                };
              }

              this.collectionData = values?.docs;
            } else {
              if (!this.secondaryKey) {
                if (!this?._valueToIncludeInPath)
                  values = await lastValueFrom(
                    this.afs
                      .collection('customers')
                      .doc(this.perms.activeCustomerId)
                      .collection(this.collection)
                      .get(),
                  );
                else {
                  //unfortunately this is harder as we will need to do two layers of queries
                  let firstLayerIds: string[] = [];
                  if (this._valueToIncludeInPath === 'reportingFacilityId')
                    firstLayerIds = (
                      await lastValueFrom(
                        this._tableReportingFacilities.getReportingFacilities(),
                      )
                    )?.map((doc) => doc.id);
                  values = { docs: [] };
                  for (const id of firstLayerIds) {
                    const currentValues = await lastValueFrom(
                      this.afs
                        .collection('customers')
                        .doc(this.perms.activeCustomerId)
                        .collection(this.collection)
                        .doc(this.#reportingYear)
                        .collection(id)
                        .get(),
                    );
                    if (currentValues.docs?.length)
                      values.docs = values.docs.concat(currentValues.docs);
                  }
                }
                this.collectionData = values?.docs?.map((doc) => {
                  return { ...doc.data(), id: doc.id };
                });
              } else {
                const results = await lastValueFrom(
                  this.afs
                    .collection('customers')
                    .doc(this.perms.activeCustomerId)
                    .collection(this.collection, (ref) =>
                      ref.where(this.secondaryKey, '==', this.secondaryValue),
                    )
                    .get(),
                );
                this.collectionData = results.docs.map((doc) => {
                  return { ...doc.data(), id: doc.id };
                });
              }
            }
            this.collectionGrabbed = true;
          }
          const foundValue = this.collectionData.find(
            (doc) =>
              doc[currentKey]?.toString()?.toLowerCase() ===
              currentColumnData.currentValue?.toString()?.toLowerCase(),
          );
          if (!foundValue)
            this.addError(
              sheetName,
              currentColumnData,
              'You can only edit existing records, please use the upload to create new records.',
              currentRowErrors,
            );
        }
        if (
          (foundColumn?.unique || foundColumn?.uniqueInSheet) &&
          (currentColumnData.currentValue ||
            currentColumnData.currentValue === 0)
        ) {
          if (!uniqueCheck[currentKey]) uniqueCheck[currentKey] = [];
          // check to see if it is unique
          if (!this.collectionGrabbed) {
            let values;

            if (this.#api) {
              const apiUrl = `${this.#apiGetRoute}${
                this.#yearInPath ? `/${this.#reportingYear}` : ''
              }`;

              if (!apiUrl) {
                throw 'Iconic air did not format this url correctly, please reach out to them to fix this.';
              }
              if (this.#apiPaginate) {
                const valuesToAdd: any[] = [];
                let currentPage = 1;
                const firstCallValues: {
                  data: any[];
                  perPage: number;
                  currentPage: number;
                  total?: number;
                } = (await lastValueFrom(
                  this._http.get(
                    apiUrl +
                      '?currentPage=' +
                      currentPage +
                      '&perPage=1000&includeTotal=true',
                  ),
                )) as {
                  data: any[];
                  perPage: number;
                  currentPage: number;
                  total?: number;
                };

                firstCallValues.data.forEach((item) => {
                  valuesToAdd.push(item);
                });

                const getMoreData = async () => {
                  currentPage++;
                  const getMoreValues = (await lastValueFrom(
                    this._http.get(
                      apiUrl +
                        '?currentPage=' +
                        currentPage +
                        '&perPage=1000&includeTotal=false',
                    ),
                  )) as {
                    data: any[];
                    perPage: number;
                    currentPage: number;
                    total?: number;
                  };

                  getMoreValues.data.forEach((item) => {
                    valuesToAdd.push(item);
                  });

                  if (firstCallValues.total) {
                    if (valuesToAdd.length < firstCallValues.total) {
                      await getMoreData();
                    }
                  } else {
                    if (getMoreValues.data.length) {
                      await getMoreData();
                    }
                  }

                  return;
                };

                if (firstCallValues.total) {
                  if (valuesToAdd.length < firstCallValues.total) {
                    await getMoreData();
                  }
                } else {
                  if (firstCallValues.data.length) {
                    await getMoreData();
                  }
                }

                values = {
                  docs: valuesToAdd,
                };
              } else {
                const valuesToAdd = await lastValueFrom(this._http.get(apiUrl));

                values = {
                  docs: valuesToAdd,
                };
              }

              this.collectionData = values?.docs;
            } else {
              const results = await lastValueFrom(
                this.afs
                  .collection('customers')
                  .doc(this.perms.activeCustomerId)
                  .collection(this.collection)
                  .get(),
              );
              this.collectionData = results.docs.map((doc) => {
                return { ...doc.data(), id: doc.id };
              });
            }
            this.collectionGrabbed = true;
          }

          let foundValue = this.collectionData.find(
            (doc) =>
              doc[currentKey]?.toString()?.toLowerCase() ===
                currentColumnData.currentValue?.toString()?.toLowerCase() &&
              foundColumn?.unique &&
              doc.id?.toString()?.toLowerCase() !==
                currentData.id?.currentValue?.toString()?.toLowerCase(),
          );
          let message =
            'However, there already exists a record in the database with this value.';
          if (!foundValue) {
            foundValue =
              uniqueCheck[currentKey]?.indexOf(
                currentColumnData.currentValue?.toString()?.toLowerCase(),
              ) > -1;
            message =
              'However, you already have another row with this value you cannot create duplicates.';
          }
          if (foundValue) {
            this.addError(
              sheetName,
              currentColumnData,
              message,
              currentRowErrors,
            );
          } else {
            uniqueCheck[currentKey].push(
              currentColumnData.currentValue?.toString()?.toLowerCase(),
            );
          }
        }
        // check if they have options and if so parse through them to see if they have a valid option
        if (
          foundColumn?.options &&
          (currentColumnData.currentValue ||
            currentColumnData.currentValue === 0)
        ) {
          let options: string[] = [],
            message = '';
          if (typeof foundColumn?.options === 'string') {
            options = foundColumn?.options
              ?.split(',')
              ?.map((value) => value?.toLowerCase());
            if (foundColumn?.options?.startsWith('customer.')) {
              options = this.customerData.customerRecord[
                foundColumn.options.split('.')[1]
              ]?.map((value) => value?.toLowerCase());
              message =
                'Value must be in your company profile values for: ' +
                foundColumn.options.split('.')[1];
            } else if (foundColumn?.options?.startsWith('staticCollection.')) {
              // now we have the values so we can check if it has that option
              options = this.staticService[foundColumn?.options.split('.')[1]];
              message =
                'Value must be in our global options for: ' +
                foundColumn?.options.split('.')[1];
            } else if (foundColumn?.options?.startsWith('bulkUpload.')) {
              options = this[foundColumn?.options.split('.')[1]];
              message =
                'Value must be in our options for: ' +
                foundColumn?.options.split('.')[1];
            }
          } else if (Array.isArray(foundColumn?.options)) {
            options = foundColumn.options;
          } else if (foundColumn?.options?.option) {
            // this is a more complex option type
            const optionsObject = foundColumn.options;
            const collectionName = optionsObject.option.split('.')[1];

            if (foundColumn?.options?.option?.startsWith('backend.')) {
              // check to see if we have already grabbed it.
              if (!this.customerCollection[collectionName]) {
                const results: any[] = (await lastValueFrom(
                  this._http.get('/api/' + kebabCase(collectionName)),
                )) as any[];
                this.customerCollection[collectionName] = results;
              }
              options = this.customerCollection[collectionName].map((doc) =>
                doc[optionsObject.value]?.toString()?.toLowerCase(),
              );
            } else if (
              foundColumn?.options?.option?.startsWith('customerCollection.')
            ) {
              // check to see if we have already grabbed it.
              if (!this.customerCollection[collectionName]) {
                const results = await lastValueFrom(
                  this.afs
                    .collection('customers')
                    .doc(this.perms.activeCustomerId)
                    .collection(collectionName)
                    .get(),
                );
                this.customerCollection[collectionName] = results.docs.map(
                  (doc) => {
                    const newDoc = { ...doc.data() };
                    newDoc.id = doc.id;
                    return newDoc;
                  },
                );
              }
              // now we have the values so we can check if it has that option
              options = this.customerCollection[collectionName].map((doc) =>
                doc[optionsObject.value]?.toString()?.toLowerCase(),
              );
              message =
                'Value must be in your company data for: ' + collectionName;
            } else if (
              foundColumn?.options?.option?.startsWith('staticCollection.')
            ) {
              options = this.staticService[
                foundColumn?.options?.option?.split('.')[1]
              ]?.map((doc) =>
                doc[optionsObject.value]?.toString()?.toLowerCase(),
              );
            }
          }
          if (
            (!options
              .map((option) => option?.toString()?.toLowerCase())
              ?.find(
                (option) =>
                  currentColumnData.currentValue?.toString()?.toLowerCase() ===
                    option?.toString()?.toLowerCase() ||
                  (foundColumn?.type === 'api14Number' &&
                    option?.toString()?.toLowerCase()?.substring(0, 10) ===
                      currentColumnData.currentValue
                        ?.toString()
                        ?.toLowerCase()
                        ?.substring(0, 10)),
              ) &&
              (!foundColumn.multiple ||
                !this._checkMultiples(
                  options,
                  currentColumnData.currentValue?.toString()?.toLowerCase(),
                ))) ||
            !options
          ) {
            let fullMessage =
              'However, it is not a supported value. ' + message;
            if (options && options.length < 100) {
              fullMessage =
                'However, the value has to be one of these: ' +
                options.join(', ');
            }
            this.addError(
              sheetName,
              currentColumnData,
              fullMessage,
              currentRowErrors,
            );
          }
        }
        // normal data type checks
        switch (foundColumn?.type) {
          case 'state':
            // Validate States
            if (!this.checkState(currentColumnData.currentValue)) {
              this.addError(
                sheetName,
                currentColumnData,
                'However, a valid state was expected.',
                currentRowErrors,
              );
            }
            break;
          case 'subBasin':
            // validate sub basin is in their basins
            if (
              this.checkSubBasin(currentColumnData.currentValue?.toString())
            ) {
              this.addError(
                sheetName,
                currentColumnData,
                'However, a sub basin from your basins are expected options are: ' +
                  this.customerData.subBasins.map(
                    (subBasin: SubBasin) => subBasin.subBasinFullNameMapped,
                  ),
                currentRowErrors,
              );
            }
            break;
          case 'email':
            // Validate email
            if (!checkEmail(currentColumnData.currentValue)) {
              this.addError(
                sheetName,
                currentColumnData,
                'However, a valid e-mail address was expected.',
                currentRowErrors,
              );
            }
            break;
          case 'longitude':
            if (!checkLongitude(currentColumnData.currentValue)) {
              this.addError(
                sheetName,
                currentColumnData,
                'However, valid coordinates were expected.',
                currentRowErrors,
              );
            }
            break;
          case 'latitude':
            if (!checkLatitude(currentColumnData.currentValue)) {
              this.addError(
                sheetName,
                currentColumnData,
                'However, valid coordinates were expected.',
                currentRowErrors,
              );
            }
            break;
          case 'year':
            // Validate Year
            if (!checkYear(currentColumnData.currentValue)) {
              this.addError(
                sheetName,
                currentColumnData,
                'However, a valid year is expected. Please use the following format: YYYY ',
                currentRowErrors,
              );
            }
            break;
          case 'date':
            // validate date
            if (
              !checkDate(
                currentColumnData.currentValue as string | number | Date,
              )
            ) {
              this.addError(
                sheetName,
                currentColumnData,
                'However, a valid date is expected. Please use the following format: MM/DD/YYYY. Note: Date can only be in the future 30 years or in the past 50 years. ',
                currentRowErrors,
              );
            }
            break;
          case 'boolean':
            // validate date
            if (
              !checkBoolean(currentColumnData.currentValue as string | boolean)
            ) {
              this.addError(
                sheetName,
                currentColumnData,
                'However, a valid boolean is expected. Please use one of the following: yes, no, true, false.',
                currentRowErrors,
              );
            }
            break;
          case 'phone':
            // Validate phone number
            if (!checkPhoneNumber(currentColumnData.currentValue)) {
              this.addError(
                sheetName,
                currentColumnData,
                'However, a valid phone number is expected. Please use the following format: ###-###-#### ',
                currentRowErrors,
              );
            }
            break;
          case 'apiNumber':
            if (
              !checkApiNumber(currentColumnData.currentValue as string | number)
            ) {
              this.addError(
                sheetName,
                currentColumnData,
                `However a valid api number is expected, it should be a 10 character number (leeding 0's might be needed) and dashes do not count to the 10 characters but are allowed.`,
                currentRowErrors,
              );
            }
            break;
          case 'api14Number':
            if (
              !checkApi14Number(
                currentColumnData.currentValue as string | number,
              )
            ) {
              this.addError(
                sheetName,
                currentColumnData,
                `However a valid api 14 number is expected, it should be a 14 character number (leeding 0's might be needed) and dashes do not count to the 14 characters but are allowed.`,
                currentRowErrors,
              );
            }
            break;
          case 'reportingPeriod':
            if (
              !checkReportingPeriod(
                currentColumnData.currentValue as string | number,
              )
            ) {
              this.addError(
                sheetName,
                currentColumnData,
                `However a valid reporting period is expected, it should be a date, year, quarter (Q1, etc) or first or second for semi annual.`,
                currentRowErrors,
              );
            }
            break;

          case 'avoProgramTypes':
            if (
              currentData['avoProgramTypes']?.currentValue &&
              currentData['avoProgramTypes']?.currentValue
                ?.toString()
                ?.toLowerCase()
                ?.includes('oooob')
            ) {
              if (
                !currentData['locationSubType'].currentValue &&
                currentData['locationSubType'].currentValue !== ''
              ) {
                this.addError(
                  sheetName,
                  currentColumnData,
                  'However, is location sub type is required for the OOOOb program type.',
                  currentRowErrors,
                );
              }
            }
          case 'ogiProgramTypes':
            if (
              currentData['ogiProgramTypes']?.currentValue &&
              currentData['ogiProgramTypes']?.currentValue
                ?.toString()
                ?.toLowerCase()
                ?.includes('oooob')
            ) {
              if (
                !currentData['locationSubType'].currentValue &&
                currentData['locationSubType'].currentValue !== ''
              ) {
                this.addError(
                  sheetName,
                  currentColumnData,
                  'However, is location sub type is required for the OOOOb program type.',
                  currentRowErrors,
                );
              }
            }
        }
      }
      if (currentRowErrors.length && !rowEmpty)
        newErrors = newErrors.concat(currentRowErrors);
    }
    if (newErrors && newErrors?.length > 0) {
      return newErrors;
    } else return null;
  }

  private _checkMultiples(options: string[], value: string | string[]) {
    let valuesToCheck = value;
    let passed = true;
    const optionsToCheck = options?.map((option) =>
      option?.toString()?.toLowerCase(),
    );
    if (!Array.isArray(value) && value.includes(','))
      valuesToCheck = value.split(',');
    else if (!Array.isArray(value) && value) valuesToCheck = [value];

    valuesToCheck = (valuesToCheck as string[])?.map((value) =>
      value?.trim()?.toLowerCase(),
    );
    valuesToCheck?.forEach((currentValue) => {
      if (!optionsToCheck.includes(currentValue)) passed = false;
    });
    return passed;
  }
}
