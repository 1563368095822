import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
} from '@angular/router';
import { User, Company } from '@iconic-air-monorepo/models';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as FS from '@fullstory/browser';

import { AuthenticationWebService } from '../../services/authentication/authentication-web.service';
import { ObservableService } from '../../services/observable-service/observable.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PermissionsWebGuard implements CanActivate, CanActivateChild {
  customers: any[] = [];
  globalRoles: any;
  companyPermissions: any;
  userData: User;
  userPermissions: any;
  private customerValuesCollection: Subscription;
  private user: Subscription;
  companies: Company[];

  private _changingCustomers: boolean;

  get activeCustomerId() {
    return this.userData?.customerIds[0];
  }

  get currentCustomer() {
    return this.customers[0];
  }

  get isInternal() {
    return this.userData?.internal;
  }

  get isAdmin() {
    return this.userData?.isAdmin;
  }

  get customerPerms() {
    return this.customers[0]?.permissions;
  }

  get email() {
    return this.userData?.email;
  }

  get uid() {
    return this.userData?.uid;
  }

  constructor(
    private afs: AngularFirestore,
    private auth: AuthenticationWebService,
    private afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private router: Router,
    private _observableService: ObservableService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.auth.user$ = this.afAuth.user
        .pipe(
          catchError((data) => {
            return data;
          }),
        )
        .subscribe((user: any) => {
          if (user) {
            this.auth.userUid = user.uid;
            // subscribe to observable service for changing users to avoid it refreshing here.
            this._observableService
              .subscribe('internal-change-company')
              .subscribe(() => {
                this._changingCustomers = true;
              });
            // subscribe to the user changing their customer and refresh to make sure you never are on a wrong customer
            this.auth.userDetails$ = this.afs
              .collection('users')
              .doc<User>(this.auth.userUid)
              .valueChanges()
              .subscribe((userData) => {
                if (
                  this.userData?.customerIds &&
                  this.userData?.customerIds[0] &&
                  userData?.customerIds &&
                  userData?.customerIds[0] &&
                  this.userData?.customerIds[0] !== userData?.customerIds[0] &&
                  !this._changingCustomers
                ) {
                  window.location.reload();
                }
              });
            return this.afs
              .collection('users')
              .doc(this.auth.userUid)
              .get()
              .pipe(
                catchError((error) => {
                  reject();
                  return error;
                }),
              )
              .subscribe(
                (userData: any) => {
                  if (userData.data()) {
                    this.userData = userData.data();
                    FS.identify(userData.data().uid, {
                      displayName: userData.data().displayName,
                      email: userData.data().email,
                      environment: environment.environmentName,
                      customerId: userData.data()?.customerIds[0],
                    });
                    const fj = [];
                    const customers: any = userData.data().customerIds;
                    const queries: Observable<any>[] = [];
                    if (customers && customers.length) {
                      customers?.forEach((el) => {
                        queries.push(this.afs.doc('/customers/' + el).get());
                      });
                    }

                    if (
                      this.userData?.customerIds &&
                      this.userData?.customerIds.length
                    ) {
                      // we need to move this because we now need to check customer id
                      forkJoin(queries)
                        .pipe(
                          catchError((error) => {
                            reject('No permissions.');
                            if (error.status === 401 || error.status === 403) {
                              // handleerror
                              reject('No permissions.');
                            }
                            return of(error);
                          }),
                        )
                        .subscribe(
                          (data: any) => {
                            this.customers = [];
                            if (data && Array.isArray(data)) {
                              for (const cc of data) {
                                this.customers.push({
                                  ...cc.data(),
                                  id: cc.id,
                                  companyValues: [],
                                });
                              }
                            }
                            resolve(true);
                          },
                          (error) => {
                            reject('No permissions.');
                          },
                        );
                    } else {
                      reject('User is not tied to a company.');
                      if (this.router.url === '/login') {
                        window.location.reload();
                      } else {
                        this.afAuth.signOut();
                      }
                    }
                  } else {
                    reject('No permissions.');
                  }
                },
                (err) => {
                  reject('No permissions.');
                },
              );
          } else {
            this.ngZone.run(() => {
              this.router.navigate(['/login']);
            });
          }
        });
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    // routes = left over routes after all params are done
    const routes = JSON.parse(JSON.stringify(route.url));
    Object.keys(route.params).forEach((param) => {
      const index = routes.findIndex((urlSegment) => {
        return urlSegment.path === route.params[param];
      });

      if (index >= 0) {
        routes.splice(index, 1);
      }
    });

    // check for single falsehood of a route
    if (routes.length) {
      let allParamsCheck = false;
      let path = '';

      for (const route of routes) {
        if (!path) {
          path += route.path;
        } else {
          path += '/' + route.path;
        }
      }

      // internal admin
      if (
        !route.parent?.routeConfig?.path &&
        route?.parent?.parent?.routeConfig?.path === 'internal-admin'
      )
        path = 'internal-admin';
      // engineering
      if (
        !route.parent?.routeConfig?.path &&
        route?.parent?.parent?.routeConfig?.path === 'engineering'
      )
        path = 'engineering';

      allParamsCheck = this.checkRoute(path);

      if (!allParamsCheck) {
        setTimeout(() => {
          this.router.navigate(['/default']);
        }, 150);
        throw 'You do not have access to view that page. You have been redirected.';
      }
    }

    let checkPath = '';
    // internal admin
    if (
      !route.parent?.routeConfig?.path &&
      route?.parent?.parent?.routeConfig?.path === 'internal-admin'
    ) {
      checkPath = 'internal-admin';
    } else {
      checkPath = route.routeConfig?.path ? route.routeConfig.path : '';
    }

    return this.checkRoute(checkPath);
  }

  public checkRoute = (route: string): boolean => {
    let type = '';
    const hasLDAR = this.customers[0].permissions?.ldar,
      hasEmissions = this.customers[0].permissions?.emissions,
      hasBenchmark = this.customers[0].permissions?.benchmark,
      hasWec = this.customers[0].permissions?.wec;

    if (route) {
      switch (route) {
        case 'internal-admin':
          type = '';
          break;
        case 'engineering':
          type = '';
          break;
        case 'admin':
          type = '';
          break;
        case 'leaks':
          type = 'ldar';
          break;
        case 'overview/leaks':
          type = 'ldar';
          break;
        case 'leaks':
          type = 'ldar';
          break;
        case 'overview':
          type = 'both';
          break;
        case 'emissions':
          type = 'emissions';
          break;
        case 'overview/emissions':
          type = 'emissions';
          break;
        case 'integrations':
          type = 'ldar';
          break;
        case 'reports/leaks':
          type = 'ldar';
          break;
        case 'survey':
          type = 'ldar';
          break;
        case 'emissions':
          type = 'emissions';
          break;
        case 'emission-facilities':
          type = 'emissions';
          break;
        case 'locations':
          type = 'both';
          break;
        case 'benchmark':
          type = 'benchmark';
          break;
        case 'wec-tool':
          type = 'wec';
          break;
      }

      // always allow admins but don't allow perms the company doesnt have
      if (
        !['internal-admin', 'engineering'].includes(route) &&
        this.userData.isAdmin &&
        (!type ||
          (hasLDAR && type === 'ldar') ||
          (hasEmissions && type === 'emissions') ||
          (hasBenchmark && type === 'benchmark') ||
          ((hasEmissions || hasLDAR) && type === 'both') ||
          (hasWec && type === 'wec'))
      ) {
        return true;
      }
    }

    // internal for internal company people
    if (route == 'internal-admin') {
      return this.userData.internal;
    }
    // engineering for engineering company people
    if (route == 'engineering') {
      return this.userData.isEngineering;
    }

    // non admin users we'll need to check company and route.
    if (this.userData?.priviledges?.length) {
      switch (route) {
        case 'admin':
          return this.userData?.isAdmin ? true : false;
        case 'integrations':
          return this.userData?.isAdmin ? true : false;
        case 'facility':
          return (hasLDAR || hasEmissions) && this.hasPerms('view_facility')
            ? true
            : false;
        case 'ldar/facilities':
          return hasLDAR && this.hasPerms('view_facility') ? true : false;
        case 'emissions/facilities':
          return hasEmissions && this.hasPerms('view_facility') ? true : false;
        case 'reports':
          return hasLDAR && this.hasPerms('view_report') ? true : false;
        case 'survey':
          return hasLDAR && this.hasPerms('view_survey') ? true : false;
        case 'locations':
          return hasLDAR && this.hasPerms('view_facility') ? true : false;
        case 'overview/leaks':
          return hasLDAR && this.hasPerms('view_survey') ? true : false;
        case 'overview/emissions':
          return hasEmissions && this.hasPerms('view_emissions') ? true : false;
        case 'overview':
          return (hasEmissions && this.hasPerms('view_emissions')) ||
            (hasLDAR && this.hasPerms('view_survey'))
            ? true
            : false;
        case 'leaks':
          return hasLDAR && this.hasPerms('view_survey') ? true : false;
        case 'dashboard':
          return hasEmissions && this.hasPerms('view_emissions') ? true : false;
        case 'emissions':
          return hasEmissions && this.hasPerms('view_emissions') ? true : false;
        case 'benchmark':
          return hasBenchmark && this.hasPerms('view_benchmark') ? true : false;
        case 'wec-tool':
          return hasWec;
        case 'profile':
          return true;
        case 'default':
          return true;
      }
    } else {
      this.router.navigate(['/profile']);
      return false;
    }

    return true;
  };

  public hasPerms(perm: string) {
    // always allow admins
    if (this.userData.isAdmin) {
      return true;
    }

    if (this.userData?.priviledges?.length) {
      const priv = this.userData.priviledges.find((priviledge) => {
        return perm === priviledge;
      });

      if (priv) {
        return true;
      }
    }

    return false;
  }
}
