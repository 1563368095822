<div
  class="rounded flex justify-between p-3 sm:p-4 my-1.5 mx-0 items-center border-[#F0F1F6] border border-solid file-row max-w-none w-full"
>
  <div class="flex justify-between items-baseline w-full gap-2 title">
    <span class="basis-80">{{title}}</span>
    <span class="flex gap-2">
      <span>
        <mat-chip-list>
          <mat-chip *ngIf="type" color="primary" selected
            >{{type | uppercase}}</mat-chip
          >
        </mat-chip-list>
      </span>
      <button
        *ngIf="url && (type !== 'web')"
        mat-stroked-button
        (click)="downloadFile()"
      >
        View
      </button>
      <button mat-stroked-button (click)="uploadFile()">
        <div class="label-large">Import</div>
      </button>
    </span>
  </div>
</div>
