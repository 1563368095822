import {
  Component,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ReportingFacility } from '@iconic-air-monorepo/models';
import { DataStreamService } from '../../pages/overview/dashboard/data-stream/data-stream.service'

@Component({
  selector: 'app-reporting-facility-select',
  templateUrl: './reporting-facility-select.component.html',
  styleUrls: ['./reporting-facility-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ReportingFacilitySelectComponent,
      multi: true,
    },
  ],
})
export class ReportingFacilitySelectComponent
  implements ControlValueAccessor, OnChanges
{
  @Input() reportingFacilities: ReportingFacility[] = [];
  @Input() multiple: boolean;
  @Input() selectedRf: string[] | string;
  @Input() classes = '';
  @Input() formControlName;
  @Input() loading: boolean;
  @Input() fullObj: boolean;
  @Input() required: boolean;
  @Input() appearance = 'outline';
  @Input() additionalStyle: string;
  @Output() selectedRfChange: EventEmitter<string[] | string> =
    new EventEmitter();
  @ViewChild('matSelect')
  selectOption: MatSelect;
  @ViewChild('matWrapper')
  selectWrapper: ElementRef;
  value: string[] | string; // Ex. CA
  isDisabled = false;
  facilityNameFilter = '';
  get localSelectedRf(): string[] {
    let availableRfs: string[] = [];
    if (this.multiple && this.reportingFacilities.length) {
      availableRfs = (this.selectedRf as string[]).filter((selected) => {
        const available = this.reportingFacilities.find((reportingFacility) => {
          return selected === reportingFacility.id;
        });
        return available;
      });
    }

    return availableRfs;
  }
  private onChangeCallback: any = () => undefined;
  private onTouchedCallback: any = () => undefined;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _dataStreamService: DataStreamService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reportingFacilities) {
      //removal
      if (changes.reportingFacilities.currentValue.length) {
        this.reportingFacilities.forEach((existingRf, index) => {
          const removal = changes.reportingFacilities.currentValue.find(
            (newRf) => {
              return newRf.id === existingRf.id;
            },
          );

          if (!removal) {
            this.reportingFacilities.splice(index, 1);
          }
        });
      }
      changes.reportingFacilities.currentValue?.forEach((reportingFacility) => {
        const existing = this.reportingFacilities.find((existingRf) => {
          return existingRf.id === reportingFacility.id;
        });

        if (!existing) {
          this.reportingFacilities.push(reportingFacility);
        }
      });
    }
    this._cdRef.detectChanges();
  }
  changeValue(val: MatSelectChange): void {
    this.value = val.value;
    this.selectedRfChange.emit(this.value);
    this.onChangeCallback(this.value);
    this._dataStreamService.filterUsed();
  }

  onSelect(event: MatOptionSelectionChange) {
    this.onChangeCallback(event.source.value);
    this.selectedRfChange.emit(event.source.value);
  }

  writeValue(value: string[]) {
    this.selectedRf = value;
  }

  // Implement ControlValueAccessor registerOnChange.
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // Implement ControlValueAccessor registerOnTouched.
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // Propagate enable/disable to the form control.
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public focus() {
    this.selectOption._elementRef.nativeElement.focus();
  }

  selectAllRfs(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedRf = JSON.parse(
        JSON.stringify(this.reportingFacilities.map((a) => a.id)),
      );
    } else {
      this.selectedRf = [];
    }
    this.value = this.selectedRf.concat();
    this.selectedRfChange.emit(this.value);
    this.onChangeCallback(this.value);
  }
}
