import { Injectable } from '@angular/core';
import { EmissionsCategory, SubBasin } from '@iconic-air-monorepo/models';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataService {
  public allZips$: Subscription;
  public customerRecord: any;
  public companyRecord: any;
  public companyValues: any = {};
  public additionalCategories: EmissionsCategory[] = [];
  public subBasins: SubBasin[] = [];
  public customerRecordValueChanges: Observable<any>;
}
