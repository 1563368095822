import { Facility } from './facility.interface';

export class Company {
  brand?: string;
  name?: string;
  displayName?: string;
  id?: string;
  regions?: string[];
  basins?: string[];
  facilities?: Facility[];
  isSelected?: boolean;
  emissionsGoal?: number;
  is1to1?: boolean;
}
