import { Component, HostListener, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { AuthenticationWebService } from '../../services/authentication/authentication-web.service';
import { MenuItem, User, MenuChildItem } from '@iconic-air-monorepo/models';
import { ContactSupportDialogComponent } from './../../components/contact-support-dialog/contact-support-dialog.component';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() mobile = false;
  public internal = false;
  public isAdmin = false;
  public navLinks: MenuItem[] = [];
  public user;
  public userDoc: AngularFirestoreDocument<User>;
  get canUpload() {
    return this.perms.hasPerms('view_emissions');
  }
  @Input() isScrolled;
  @Input() isHeaderClosed;

  dataStreamEnabled = true;

  constructor(
    private perms: PermissionsWebGuard,
    private router: Router,
    private authService: AuthenticationWebService,
    private firebaseAuth: AngularFireAuth,
    private location: Location,
    private dialog: MatDialog,
    private actRouter: ActivatedRoute,
  ) {
    this.firebaseAuth.user.subscribe((user) => {
      this.user = user;
    });
    this.isAdmin = this.perms.userData?.isAdmin;
    this.internal = this.perms.userData?.internal;
    this.dataStreamEnabled = this.perms.currentCustomer?.dataStreamEnabled;

    const analyze: MenuItem = {
      title: 'Analyze',
      children: [],
    };
    const plan: MenuItem = {
      title: 'Plan',
      children: [],
    };
    const manage: MenuItem = {
      title: 'Manage',
      children: [],
    };
    const control: MenuItem = {
      title: 'Control',
      children: [],
    };

    if (this.perms.customers[0].permissions) {
      // ----- Analyze -----
      // Overview
      if (this.perms.customers[0].permissions.emissions) {
        if (
          this.perms.hasPerms('view_emissions') &&
          this.perms.hasPerms('view_facility')
        ) {
          analyze.children.push({
            label: 'Overview',
            path: '/overview/emissions',
          });
        }
      } else if (this.perms.customers[0].permissions.ldar) {
        if (
          this.perms.hasPerms('view_emissions') &&
          this.perms.hasPerms('view_facility')
        ) {
          analyze.children.push({
            label: 'Overview',
            path: '/overview/leaks',
          });
        }
      }

      // Reports (additional logic check in report wrapper needed)
      if (
        (this.perms.hasPerms('create_emissions_report') &&
          this.perms.isInternal) ||
        this.perms.hasPerms('view_report')
      ) {
        if (this.perms.customers[0].permissions.ldar) {
          analyze.children.push({
            label: 'Reports',
            path: '/reports/leaks',
          });
        }
      }

      // Benchmarks
      if (this.perms.customers[0].permissions.benchmark) {
        if (this.perms.hasPerms('view_benchmark')) {
          analyze.children.push({
            label: 'Benchmarks',
            path: '/analytics/benchmark',
          });
        }
      }

      // ----- Plan -----
      // Forecast
      if (this.perms.customers[0].permissions.forecast) {
        plan.children.push({
          label: 'Forecast',
          path: '/forecast',
        });
      }
      if (this.perms.customers[0].permissions.wec) {
        plan.children.push({
          label: 'WEC',
          path: '/wec-tool',
        });
      }

      // Projects
      if (this.perms.customers[0].permissions.abatement) {
        plan.children.push({
          label: 'Projects',
          path: '/analytics/abatement',
        });
      }

      // ----- Manage -----
      // Emissions
      if (
        this.perms.customers[0].permissions.emissions &&
        this.perms.hasPerms('view_emissions')
      ) {
        manage.children.push({
          label: 'Emissions',
          path: '/emissions',
        });

        if (this.dataStreamEnabled) {
          manage.children.push({
            label: 'Data stream',
            path: '/overview/stream',
          });
        }
      }

      if (this.perms.customers[0].permissions.ldar) {
        manage.children.push({
          label: 'Leaks',
          path: '/leaks',
        });
      }
      // Leaks (Coming Later)

      // ----- Control -----
      // Facilities
      if (
        this.perms.customers[0].permissions.emissions ||
        this.perms.customers[0].permissions.ldar
      ) {
        if (this.perms.hasPerms('view_facility')) {
          control.children.push({
            label: 'Locations',
            path: '/locations',
          });
        }
      }

      // Data Source
      if (this.perms.customers[0].permissions.emissions) {
        if (this.perms.hasPerms('view_emissions')) {
          control.children.push({
            label: 'Equipment',
            path: '/equipment',
          });

          control.children.push({
            label: 'Data sources',
            path: '/data-sources',
          });
        }
      }
    }

    this.navLinks.push(analyze);
    this.navLinks.push(plan);
    this.navLinks.push(manage);
    this.navLinks.push(control);
  }

  signOut() {
    this.authService.signOut();
  }

  public isActive(link: MenuChildItem): boolean {
    const pathLength = link?.path?.length;
    let allChildren: MenuChildItem[] = [];
    this.navLinks.forEach((parent) => {
      allChildren = allChildren.concat(parent.children);
    });
    const longerMatchingPaths = allChildren.filter(
      (child) =>
        child?.path?.length > pathLength &&
        this.router.url.startsWith(child.path),
    );
    if (longerMatchingPaths?.length) return false;
    if (this.router.url.startsWith(link.path)) return true;
    return false;
  }

  public openContactDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'dialog-flush-bottom-footer';

    this.dialog.open(ContactSupportDialogComponent, dialogConfig);
  }
}
