import { Company } from './company.interface';
import { Facility } from './facility.interface';
import { Leak } from './leak.interface';
import { Survey } from './survey.interface';

export class quarterlySurvey implements Survey {
  camera: '';
  referenceId: '';
  facility: Facility;
  client: Company;
  requestedDate: '';
  isCompleted: boolean;
  date: number;
  inspectionStartDate: number;
  inspectionEndDate: number;
  facilityId: string;
  facilityName: string;
  leaks: Leak[];
  operator: string;
  certificationDate: string;
  regulation?: string;
  programType?: string;
  region: string;
  deviations: '';
  ambient: any;
  windSpeed: number;
  ambientTemp: number;
  skyCondition: '';
  userId: string;
  quarter: number;
  surveyId: string;
}
