import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { StaticDataService } from '../../services/static-data/static.data.service';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { State } from '@iconic-air/models';

@Component({
  selector: 'app-state-editor',
  templateUrl: './state-editor.component.html',
  styleUrls: ['./state-editor.component.scss'],
})
export class StateEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  public params: ICellEditorParams;
  public value: State | undefined;
  @ViewChild('matSelect')
  public selectOption: MatSelect;
  @ViewChild('matWrapper')
  public selectWrapper: ElementRef;
  get states() {
    return this.staticService.states;
  }

  constructor(private staticService: StaticDataService) {}

  public ngAfterViewInit() {
    window.setTimeout(() => {
      this.selectOption.toggle();
      this.selectOption.focus();
      this.selectWrapper.nativeElement.focus();
    });
  }

  public changeValue(val: MatSelectChange): void {
    this.value = val.value;
    const state = this.states.find((cc) => {
      return cc === this.value;
    });
    this.params.node.setDataValue(this.params.column, state);
  }

  public getValue(): any {
    const state = this.states.find((cc) => {
      return cc === this.value;
    });
    this.params.data.address
      ? (this.params.data.address.state = state)
      : (this.params.data.address = { state: state });
    return state;
  }

  public agInit(params: any): void {
    this.params = params;
    if (params.value) {
      const state = this.states.find((cc) => {
        return cc === params.value;
      });
      this.value = state;
    }
  }

  public isPopup(): boolean {
    return true;
  }

  public onKeyDown(event: any): void {
    const key = event.which || event.keyCode;
    if (key === 38 || key === 40) {
      this.preventDefaultAndPropagation(event);
    }
  }

  private preventDefaultAndPropagation(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }
}
