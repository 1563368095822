import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { PermissionsWebGuard } from '../../../guards/permissions/permissions-web-guard.guard';
import { SaveService } from '../../../services/save/save.service';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss'],
})
export class UploadFileDialogComponent implements OnInit {
  facilityId: string;
  reportingFacilityId: string;
  private pathAndFilePrepend: string;
  private downloadUrlKey: string;
  toFile;
  percentComplete = 0;
  isUploading = false;
  downloadURL: string;

  @Input() title: string;

  constructor(
    public dialog: MatDialog,
    public perms: PermissionsWebGuard,
    private afs: AngularFirestore,
    private _saveService: SaveService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.facilityId = data.facilityId;
    this.reportingFacilityId = data.reportingFacilityId;
    this.pathAndFilePrepend = data.pathAndFilePrepend;

    this.downloadUrlKey = data.downloadUrlKey;
    this.title = data.title;
  }

  ngOnInit() {}

  async submit() {
    this.isUploading = true;
    const file = this.toFile.item(0);
    this.downloadURL = await this._saveService.uploadFile(
      file,
      this.pathAndFilePrepend,
      file.name,
      false,
    );

    this.isUploading = false;
    this.dismissModal();

    // TODO: this should be moved to components after the dialog closes....

    if (this.facilityId && this.reportingFacilityId) {
      throw 'Error: only one facility id type is supported.';
    }
    if (this.reportingFacilityId) {
      this.afs
        .collection('customers')
        .doc(this.perms.activeCustomerId)
        .collection('reportingFacilities')
        .doc(this.reportingFacilityId)
        .update({
          [this.downloadUrlKey]: this.downloadURL,
        });
    } else if (this.facilityId) {
      this.afs
        .collection('customers')
        .doc(this.perms.activeCustomerId)
        .collection('facilities')
        .doc(this.facilityId)
        .update({
          [this.downloadUrlKey]: this.downloadURL,
        });
    } else {
      throw 'Facility ID or Reporting Facility ID Required.';
    }
  }

  onChange(event) {
    this.toFile = event.target.files;
  }

  dismissModal() {
    this.dialog.closeAll();
  }
}
