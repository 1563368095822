import { Injectable } from '@angular/core';
import { isNumber } from 'lodash';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  utcToLocaleDate(utcTimestamp: number | Date) {
    // if its too small it is probably an excel date, so convert it
    if (isNumber(utcTimestamp) && utcTimestamp < 100000)
      utcTimestamp = utcTimestamp * 86400000 - 2209075200000;
    const date = new Date(utcTimestamp);
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
    );
  }

  localeDateToUtc(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
  }

  convertToDate(
    date: string | number | Date | { seconds: number },
    convertToUtc?: boolean,
  ) {
    if (!date) return date;
    if (isNumber(date)) {
      // if its too small it is probably an excel date, so convert it
      if (date < 100000) date = date * 86400000 - 2209075200000;
      date = new Date(date);
    } else if (typeof date === 'string') date = new Date(date);
    else if ('seconds' in date) date = new Date(date.seconds * 1000);
    if (convertToUtc) date = this.localeDateToUtc(date as Date);
    return date;
  }
  convertToW3CDate(
    date: string | number | Date | { seconds: number },
    convertToUtc?: boolean,
  ) {
    if (!date) return date;
    if (isNumber(date)) {
      // if its too small it is probably an excel date, so convert it
      if (date < 100000) date = date * 86400000 - 2209075200000;
      date = new Date(date);
    } else if (typeof date === 'string') date = new Date(date);
    else if ('seconds' in date) date = new Date(date.seconds * 1000);
    if (convertToUtc) date = this.localeDateToUtc(date as Date);
    return moment(date).format();
  }

  displayDatetime(date: string | number | Date | { seconds: number }) {
    return this.convertToDate(date)?.toLocaleString();
  }
  monthsAgo(dateInMillis: number) {
    const givenDate = new Date(dateInMillis);
    const currentDate = new Date();

    // Get year and month for both the current date and the given date
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0-indexed (January = 0)

    const givenYear = givenDate.getFullYear();
    const givenMonth = givenDate.getMonth(); // 0-indexed (January = 0)

    // Calculate the difference in years and months
    const yearDiff = currentYear - givenYear;
    const monthDiff = currentMonth - givenMonth;

    // Calculate total months difference
    const totalMonthsAgo = yearDiff * 12 + monthDiff;

    return totalMonthsAgo;
  }
}
