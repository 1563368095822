import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, from } from 'rxjs';
import { Observable } from 'rxjs-compat';

import { AuthenticationWebService } from '../../../../services/authentication/authentication-web.service';
import { PermissionsWebGuard } from '../../../../guards/permissions/permissions-web-guard.guard';
import { Facility, ReportingFacility } from '@iconic-air-monorepo/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  get customers() {
    return this.perms.customers;
  }

  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationWebService,
    private perms: PermissionsWebGuard,
  ) {}

  get(facilityId: string): Observable<Facility> {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('facilities')
      .doc<Facility>(facilityId)
      .valueChanges();
  }

  public getDrawingOptions(facilityId: string) {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('facilities')
      .doc(facilityId)
      .collection('drawings')
      .valueChanges();
  }

  public getFacilities(customerId?: string) {
    return this.afs
      .collection('customers')
      .doc(customerId || this.perms.activeCustomerId)
      .collection('facilities')
      .valueChanges();
  }

  public getFacilityInformation(customerId?: string, facilityId?: string) {
    return this.afs
      .collection('customers')
      .doc(customerId || this.perms.activeCustomerId)
      .collection('facilities')
      .doc(facilityId)
      .valueChanges();
  }

  public getLocationOptions(facilityId: string) {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('facilities')
      .doc(facilityId)
      .collection('awpLocations')
      .valueChanges();
  }

  public getSubLocationOptions(facilityId: string) {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('facilities')
      .doc(facilityId)
      .collection('subLocations')
      .valueChanges();
  }

  save(facility: Facility): Observable<void> {
    const valueToSave = JSON.parse(JSON.stringify(facility)) as Facility;
    valueToSave.facilityType = valueToSave.facilityType?.toLowerCase();
    return from(
      this.afs
        .doc(
          `customers/${this.customers[0].id}/facilities/${valueToSave.facilityId}`,
        )
        .update(valueToSave)
        .catch((error) => console.error(error)),
    );
  }

  saveReportingFacility(
    reportingFacility: ReportingFacility,
  ): Observable<void> {
    return from(
      this.afs
        .doc(
          `customers/${this.customers[0].id}/reportingFacilities/${reportingFacility.id}`,
        )
        .update(reportingFacility)
        .catch((error) => console.error(error)),
    );
  }

  components(facilityId: string): Observable<any> {
    return this.afs
      .collection('customers')
      .doc(this.customers[0].id)
      .collection('components', (ref) =>
        ref.where('facilityId', '==', facilityId),
      )
      .valueChanges();
  }

  getAVOReportingFrequency() {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('avoReportingFrequency')
      .valueChanges();
  }

  getLeaksRepairsAndSurveys() {
    const leaks = this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('leaks')
      .valueChanges();

    const repairs = this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('repairs')
      .valueChanges();

    const surveys = this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('surveys')
      .valueChanges();

    return combineLatest([leaks, repairs, surveys]).pipe(
      map(([leaks, repairs, surveys]) => ({
        leaks: leaks,
        repairs: repairs,
        surveys: surveys,
      })),
    );
  }

  async checkForDuplicates(uploadData, dbData, type: 'leaks' | 'surveys') {
    const dataFromDB = dbData[type];
    const dataNotFoundInDB: any[] = [];
    const allDataFoundInDB: any[] = [];

    if (type === 'leaks') {
      uploadData.forEach((d) => {
        const dataFoundInDB = dataFromDB.find(
          (data) =>
            data.detectionDate === d.detectionDate &&
            data.facilityId === d.facilityId &&
            data.type === d.type,
        );

        if (!dataFoundInDB) {
          dataNotFoundInDB.push(d);
        } else {
          allDataFoundInDB.push(d);
        }
      });
    } else if (type === 'surveys') {
      uploadData.forEach((d) => {
        const dataFoundInDB = dataFromDB.find(
          (data) =>
            data.inspectionStartDate === d.inspectionStartDate &&
            data.facilityId === d.facilityId &&
            data.type === d.type,
        );
        if (!dataFoundInDB) {
          dataNotFoundInDB.push(d);
        } else {
          allDataFoundInDB.push(d);
        }
      });
    }

    return [dataNotFoundInDB, allDataFoundInDB];
  }

  getMapboxVisibility() {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('mapboxVisibility')
      .valueChanges();
  }

  setMapboxVisibility(state) {
    return this.afs
      .collection('customers')
      .doc(this.perms.activeCustomerId)
      .collection('companyValues')
      .doc('mapboxVisibility')
      .update({
        visible: state,
      });
  }
}
