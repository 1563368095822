<div class="wrapper">
  <div class="upload" *ngIf="!isUploading">
    <h4>Import {{title}} File</h4>
    <input type="file" mat-button (change)="onChange($event)" />
    <br />
    <div class="action-buttons">
      <button mat-button (click)="dismissModal()" style="margin-right: 5px">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!toFile"
        (click)="submit()"
      >
        <div class="label-large">Import</div>
      </button>
    </div>
  </div>

  <div class="uploading-in-progress" *ngIf="isUploading">
    <h4>Uploading File...</h4>
    <span>{{percentage$ | async | number: '1.0-0' }} %</span>
  </div>
</div>
