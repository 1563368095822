import { Injectable } from '@angular/core';
import { isNumber } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  utcToLocaleDate(utcTimestamp: number | Date) {
    // if its too small it is probably an excel date, so convert it
    if (isNumber(utcTimestamp) && utcTimestamp < 100000)
      utcTimestamp = utcTimestamp * 86400000 - 2209075200000;
    const date = new Date(utcTimestamp);
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
    );
  }

  localeDateToUtc(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
  }

  convertToDate(
    date: string | number | Date | { seconds: number },
    convertToUtc?: boolean,
  ) {
    if (!date) return date;
    if (isNumber(date)) {
      // if its too small it is probably an excel date, so convert it
      if (date < 100000) date = date * 86400000 - 2209075200000;
      date = new Date(date);
    } else if (typeof date === 'string') date = new Date(date);
    else if ('seconds' in date) date = new Date(date.seconds * 1000);
    if (convertToUtc) date = this.localeDateToUtc(date as Date);
    return date;
  }

  displayDatetime(date: string | number | Date | { seconds: number }) {
    return this.convertToDate(date)?.toLocaleString();
  }
}
