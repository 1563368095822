<mat-form-field
  [appearance]="appearance"
  class="{{classes}}"
  matTooltip="{{ multiple ? (localSelectedRf?.length ? localSelectedRf.length + ' Selected' : ''): '' | titlecase}}"
  matTooltipPosition="below"
>
  <mat-label *ngIf="!multiple">Facility</mat-label>

  <mat-select
    [(ngModel)]="selectedRf"
    [multiple]="multiple"
    (selectionChange)="changeValue($event)"
    [required]="required"
  >
    <mat-select-trigger *ngIf="multiple && appearance === 'outline'">
      <ng-container *ngIf="loading">
        <mat-progress-spinner
          *ngIf="loading"
          [diameter]="18"
          [mode]="'indeterminate'"
        >
        </mat-progress-spinner>
      </ng-container>
      <ng-container *ngIf="!loading">
        <span class="text-primary">Facility: </span>
        {{selectedRf?.length === reportingFacilities.length ? 'All' :
        selectedRf.length + ' Selected'}}
      </ng-container>
    </mat-select-trigger>
    <form novalidate class="mat-filter">
      <input
        matInput
        [(ngModel)]="facilityNameFilter"
        [ngModelOptions]="{standalone: true}"
        filterBasinszclass="mat-filter-input"
        placeholder="Search..."
      />
    </form>
    <div class="example-margin" ripple *ngIf="multiple">
      <mat-checkbox
        color="primary"
        [checked]="localSelectedRf.length === reportingFacilities.length"
        (change)="selectAllRfs($event)"
        class="w-100"
        >All
      </mat-checkbox>
    </div>
    <mat-option
      *ngFor="let rf of reportingFacilities | sort:'asc':'reportingFacilityName'"
      [value]="fullObj ? rf : rf.id"
      [ngClass]="{'hidden': facilityNameFilter && rf?.reportingFacilityName?.toLowerCase().indexOf(facilityNameFilter.toLowerCase()) === -1}"
    >
      <span
        [innerHtml]="rf.reportingFacilityName | titlecase | highlight:facilityNameFilter"
      ></span>
    </mat-option>
  </mat-select>
</mat-form-field>
