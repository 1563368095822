<mat-spinner *ngIf="loading"></mat-spinner>
<div class="flex flex-col flex-auto w-full" *ngIf="!loading">
  <!-- Title / Breadcrumb -->
  <div class="flex flex-wrap w-full p-6 md:p-8" *ngIf="!isAdminPage">
    <div class="flex items-center justify-between w-full">
      <div>
        <div
          class="flex flex-row text-3xl font-semibold tracking-tight leading-8 text-secondary"
        >
          <!-- Parent -->
          <span
            (click)="navigateBack()"
            class="hidden-sm-down flex flex-row items-center justify-center cursor-pointer"
          >
            {{bulkUploadValues?.downloadName}}
            <mat-icon>chevron_right</mat-icon>
          </span>

          <!-- current breadcrumbs -->
          <ng-container>
            <!-- what we are uploading -->
            Import {{bulkUploadValues?.downloadName}}
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- Steps -->
  <div
    class="flex flex-wrap justify-between w-full"
    [ngClass]="{
      'md:px-8': !isAdminPage,
      'px-6': !isAdminPage
    }"
  >
    <mat-vertical-stepper
      class="create-comp-mat-horizontal-stepper"
      linear
      #stepper
    >
      <mat-step
        *ngIf="bulkUploadValues?.yearInPath"
        [stepControl]="reportingYearForm"
      >
        <ng-template matStepLabel>Select Year</ng-template>
        <form class="sheet" [formGroup]="reportingYearForm">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Reporting Year</mat-label>
            <mat-select formControlName="reportingYear">
              <ng-container *ngFor="let reportingYear of reportingYears">
                <mat-option [value]="reportingYear">
                  {{ reportingYear | titlecase }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </form>
        <button mat-button matStepperNext>Next</button>
      </mat-step>
      <!-- Step 1 -->
      <mat-step [stepControl]="fileForm">
        <ng-template matStepLabel>File Import</ng-template>
        <p class="mt-4 mb-8">
          Please upload a spreadsheet file of
          {{bulkUploadValues?.downloadName}}. Please ensure that it contains
          only one sheet and contains headers. Please download our example sheet
          <a (click)="downloadExample()">here.</a>
        </p>
        <p class="mt-4 mb-8" *ngIf="bulkUploadValues?.allowUpdateSheet">
          If you want to mass edit download the
          <a (click)="downloadExample(true)">current values</a> and make edits
          and upload edits.
        </p>

        <div class="mb-4" id="file-upload">
          <!-- Import Input -->
          <input
            (change)="uploadFile($event)"
            (click)="onFileClear($event)"
            hidden
            [id]="fileUploadId"
            type="file"
            #fileUpload
          />
          <!-- Import button -->
          <div class="flex items-center">
            <ng-container *ngIf="fileUploaded">
              <mat-icon class="flex-none mr-4"> check_circle_outline </mat-icon>
              <div class="flex-grow">{{ fileForm.getRawValue().fileName }}</div>
            </ng-container>

            <button
              class="upload-button inline-block"
              (click)="massEdit=false;fileUpload.click()"
              color="primary"
              [disabled]="fileLoading"
              mat-flat-button
            >
              <div class="flex justify-center items-center">
                <mat-icon>edit</mat-icon>
                <div class="ml-2" class="label-large">
                  {{(fileUploaded)? 'Reupload' : 'Import'}}
                </div>
              </div>
            </button>
            <button
              class="upload-button inline-block ml-2"
              (click)="massEdit=true;fileUpload.click()"
              color="primary"
              [disabled]="fileLoading"
              mat-flat-button
            >
              <div class="flex justify-center items-center">
                <mat-icon>edit</mat-icon>
                <div class="ml-2" class="label-large">
                  {{(fileUploaded)? 'Reupload Mass Edit' : 'Import Mass Edit'}}
                </div>
              </div>
            </button>
            <mat-spinner
              class="inline-block ml-2"
              diameter="24"
              *ngIf="fileLoading"
            ></mat-spinner>
          </div>
        </div>

        <!-- Next -->
        <div class="action-buttons pt-4">
          <button
            [disabled]="fileForm.invalid"
            mat-stroked-button
            matStepperNext
            (click)="setColumnDefs()"
          >
            Next
          </button>
        </div>
      </mat-step>

      <!-- Step 2 -->
      <mat-step>
        <ng-template matStepLabel>Save Data</ng-template>
        <ag-grid-angular
          #agGrid
          class="ag-grid-z ag-grid-iconic ag-theme-material"
          [columnDefs]="columnDefs"
          [gridOptions]="gridOptions"
          [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        >
        </ag-grid-angular>

        <!-- Back / Save -->
        <div class="action-buttons mt-4 pt-4">
          <button mat-stroked-button matStepperPrevious>Back</button>
          <button
            class="ml-2"
            color="primary"
            mat-flat-button
            (click)="save()"
            [disabled]="saving"
          >
            Save
          </button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>
