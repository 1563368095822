import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialog,
} from '@angular/material/dialog';
import { ContactSupportDialogComponent } from '../contact-support-dialog/contact-support-dialog.component';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  public errors: any[] = [];
  public title = '';

  contactSupportEnabled = false;
  hasLongMessage = false;
  showLong = false;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    public cdRef: ChangeDetectorRef,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any | any[],
  ) {
    if (data instanceof Array) this.errors = data;
    else this.errors = [data];
    this.title = this.errors?.find((err) => err.title)?.title;
  }

  get buttonText(): string {
    return this.locData.length > 1 ? 'Dismiss All' : 'Dismiss';
  }

  get locData(): any[] {
    let lastErrorMessage: string;
    let lastErrorCount = 1;
    const retArray: any[] = [];
    this.errors.forEach((err) => {
      if (err.messageLong) this.hasLongMessage = true;
      if (
        err.message.includes('undefined') ||
        err.message.includes('DocumentReference.collection')
      ) {
        err.messageLong = JSON.parse(JSON.stringify(err.message));
        this.hasLongMessage = true;
        err.message =
          'There was a data issue, please contact support below to have someone look at it';
        this.contactSupportEnabled = true;
      }
      if (err?.message?.toLowerCase()?.includes('http failure response for')) {
        err.messageLong = JSON.parse(JSON.stringify(err.message));
        this.hasLongMessage = true;
        err.message =
          'There was an issue connecting to the server, please contact support below to have someone look at it';
        this.contactSupportEnabled = true;
      }
      const promiseMessage = 'Uncaught (in promise): ';
      if (err.message.includes(promiseMessage))
        this.contactSupportEnabled = true;
      err.message = err.message.replace(promiseMessage, '');
      if (lastErrorMessage !== undefined) {
        if (lastErrorMessage === err.message) {
          lastErrorCount += 1;
          retArray[retArray.length - 1].count = lastErrorCount;
        } else {
          retArray.push(err);
          lastErrorMessage = err.message;
          lastErrorCount = 1;
        }
      } else {
        if (
          typeof err === 'object' &&
          err.message &&
          err.message.indexOf('Object:') >= 1
        ) {
          this.contactSupportEnabled = true;
          err.message = JSON.parse(err.message.split('Object:')[1])?.message;
        } else if (
          typeof err === 'object' &&
          err.message &&
          err.message.indexOf('Error:') >= 1
        ) {
          this.contactSupportEnabled = true;
          err.message = err.message.split('Error:')[1];
        }

        retArray.push(err);
        lastErrorMessage = err.message;
      }
    });

    return retArray;
  }

  openContactDialog() {
    document.getElementById('closeError')?.click();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'dialog-flush-bottom-footer';
    dialogConfig.data = {
      errors: this.errors,
    };

    this._dialog.open(ContactSupportDialogComponent, dialogConfig);
  }
}
