<div id="gas-sample-wrapper" class="flex flex-col overflow-hidden">
  <mat-toolbar class="bottom-toolbar p-4 border-b border-solid">
    <h4>Add Gas Sample</h4>
  </mat-toolbar>
  <div
    class="flex flex-col p-4 md:p-6 flex-auto overflow-y-auto"
    [formGroup]="formGroup"
  >
    <div>
      <div class="font-semibold text-xl mb-4">Gas Sample Info</div>
      <div class="flex flex-wrap">
        <mat-form-field appearance="outline" class="flex-1">
          <mat-label>Name</mat-label>
          <input
            type="text"
            matInput
            formControlName="name"
            placeholder="Name"
          />
        </mat-form-field>
        <div class="w-8 h-8"></div>
        <mat-form-field appearance="outline">
          <mat-label>Select a sample date</mat-label>
          <input
            matInput
            [matDatepicker]="samplePicker"
            formControlName="sampleDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="samplePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #samplePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" class="w-full pb-7 mb-7">
        <mat-label>Select an effective date</mat-label>
        <input
          matInput
          [matDatepicker]="effectivePicker"
          formControlName="effectiveDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="effectivePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #effectivePicker></mat-datepicker>
        <mat-hint
          >Changes to the gas sample will apply to all emission calculation
          periods with a start date equal to or later than the effective date
          (until a new sample with a new effective date is available). Updating
          gas samples does not trigger recalculation automatically.
          Recalculation (reupload) of reading values is required for the changes
          to take effect. Note that facility-level gas samples always supersede
          reporting facility-level gas samples</mat-hint
        >
      </mat-form-field>
    </div>
    <div>
      <div class="font-semibold text-xl mb-4">Constituents</div>
      <div class="flex flex-wrap">
        <mat-form-field appearance="outline" class="flex-1 my-1">
          <mat-label>{{'Mole Fraction C1 (CH4)' | emission}}</mat-label>
          <input
            type="number"
            matInput
            formControlName="ch4"
            [max]="1"
            [min]="0"
            [placeholder]="'Mole Fraction C1 (CH4)' | emission"
          />
          <mat-error *ngIf="errors?.ch4">
            <div *ngIf="errors?.ch4?.required">
              Mole Fraction C1 is required.
            </div>
            <div *ngIf="errors?.ch4?.min">
              Mole Fraction C1 cannot be less than {{ errors?.ch4?.min?.min }}.
            </div>
            <div *ngIf="errors?.ch4?.max">
              Mole Fraction C1 cannot be greater than {{ errors?.ch4?.max?.max
              }}.
            </div>
          </mat-error>
        </mat-form-field>
        <div class="w-8 h-8"></div>
        <mat-form-field appearance="outline" class="flex-1 my-1">
          <mat-label>Mole Fraction C2</mat-label>
          <input
            type="number"
            [max]="1"
            [min]="0"
            matInput
            formControlName="c2"
            placeholder="Mole Fraction C2"
          />
          <mat-error *ngIf="errors?.c2">
            <div *ngIf="errors?.c2?.required">
              Mole Fraction C2 is required.
            </div>
            <div *ngIf="errors?.c2?.min">
              Mole Fraction C2 cannot be less than {{ errors?.c2?.min?.min }}.
            </div>
            <div *ngIf="errors?.c2?.max">
              Mole Fraction C2 cannot be greater than {{ errors?.c2?.max?.max
              }}.
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-wrap">
        <mat-form-field appearance="outline" class="flex-1 my-1">
          <mat-label>Mole Fraction C3</mat-label>
          <input
            type="number"
            matInput
            formControlName="c3"
            placeholder="Mole Fraction C3"
            [max]="1"
            [min]="0"
          />
          <mat-error *ngIf="errors?.c3">
            <div *ngIf="errors?.c3?.required">
              Mole Fraction C3 is required.
            </div>
            <div *ngIf="errors?.c3?.min">
              Mole Fraction C3 cannot be less than {{ errors?.c3?.min?.min }}.
            </div>
            <div *ngIf="errors?.c3?.max">
              Mole Fraction C3 cannot be greater than {{ errors?.c3?.max?.max
              }}.
            </div>
          </mat-error>
        </mat-form-field>
        <div class="w-8 h-8"></div>
        <mat-form-field appearance="outline" class="flex-1 my-1">
          <mat-label>Mole Fraction C4</mat-label>
          <input
            type="number"
            matInput
            formControlName="c4"
            placeholder="Mole Fraction C4"
            [max]="1"
            [min]="0"
          />
          <mat-error *ngIf="errors?.c4">
            <div *ngIf="errors?.c4?.required">
              Mole Fraction C4 is required.
            </div>
            <div *ngIf="errors?.c4?.min">
              Mole Fraction C4 cannot be less than {{ errors?.c4?.min?.min }}.
            </div>
            <div *ngIf="errors?.c2?.max">
              Mole Fraction C4 cannot be greater than {{ errors?.c4?.max?.max
              }}.
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-wrap">
        <mat-form-field appearance="outline" class="flex-1 my-1">
          <mat-label>Mole Fraction C5+</mat-label>
          <input
            type="number"
            matInput
            formControlName="c5"
            placeholder="Mole Fraction C5+"
            [max]="1"
            [min]="0"
          />
          <mat-error *ngIf="errors?.c5">
            <div *ngIf="errors?.c5?.required">
              Mole Fraction C5+ is required.
            </div>
            <div *ngIf="errors?.c5?.min">
              Mole Fraction C5+ cannot be less than {{ errors?.c5?.min?.min }}.
            </div>
            <div *ngIf="errors?.c5?.max">
              Mole Fraction C5+ cannot be greater than {{ errors?.c5?.max?.max
              }}.
            </div>
          </mat-error>
        </mat-form-field>
        <div class="w-8 h-8"></div>
        <mat-form-field appearance="outline" class="flex-1 my-1">
          <mat-label>{{'Mole Fraction CO2' | emission}}</mat-label>
          <input
            type="number"
            matInput
            formControlName="co2"
            [placeholder]="'Mole Fraction CO2' | emission"
            [max]="1"
            [min]="0"
          />
          <mat-error *ngIf="errors?.co2">
            <div *ngIf="errors?.co2?.required">
              {{'Mole Fraction CO2' | emission}} is required.
            </div>
            <div *ngIf="errors?.co2?.min">
              {{'Mole Fraction CO2' | emission}} cannot be less than {{
              errors?.c2?.min?.min }}.
            </div>
            <div *ngIf="errors?.co2?.max">
              {{'Mole Fraction CO2' | emission}} cannot be greater than {{
              errors?.c2?.max?.max }}.
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-wrap">
        <mat-form-field appearance="outline" class="flex-1 my-1">
          <mat-label>Gas sample basis</mat-label>
          <mat-select formControlName="gasSampleBasis">
            <mat-option value="Continuous gas composition analyzer"
              >Continuous gas composition analyzer</mat-option
            >
            <mat-option value="Sampling and analysis"
              >Sampling and analysis
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex flex-wrap">
        <mat-form-field appearance="outline" class="flex-1 my-1">
          <mat-label>HHV basis</mat-label>
          <mat-select formControlName="hhvBasis">
            <mat-option value="Continuous gas composition analyzer"
              >Continuous gas composition analyzer</mat-option
            >
            <mat-option value="Calorimiter">Calorimiter </mat-option>
            <mat-option value="Calculated based on composition of stream"
              >Calculated based on composition of stream
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex flex-wrap">
        <mat-form-field appearance="outline" class="flex-1 my-1">
          <mat-label>HHV (BTU/scf)</mat-label>
          <input
            type="number"
            matInput
            formControlName="hhvBtu"
            placeholder="HHV"
          />
        </mat-form-field>
      </div>
      <mat-error *ngIf="errors?.sumExceeded">
        <div>The sum of all mole fractions cannot exceed 1.</div>
      </mat-error>
    </div>
    <mat-toolbar
      class="sticky bottom-0 justify-end p-4 border-t border-solid border-gray-400"
    >
      <button mat-stroked-button color="warn" (click)="this.dialogRef.close()">
        Cancel
      </button>
      <button
        mat-flat-button
        class="ml-2"
        color="primary"
        (click)="save()"
        [disabled]="!formGroup .valid"
      >
        <ng-container *ngIf="!data.isNewSample; else addSample"
          >Edit</ng-container
        >
        <ng-template #addSample>Add</ng-template>
        Sample
      </button>
    </mat-toolbar>
  </div>
</div>
