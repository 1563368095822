import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-error-completed',
  templateUrl: './upload-error-completed.component.html',
  styleUrls: ['./upload-error-completed.component.scss']
})
export class UploadErrorCompletedComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<UploadErrorCompletedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public gotoFacility = (): void => {
    this.router.navigate(['/facility/' + this.data.facilityId]).then(() => {
      this.dialogRef.close();
    });
  };

  public navigateBack = (): void => {
    this.router
      .navigate(['/facility/' + this.data.facilityId + '/components'])
      .then(() => {
        this.dialogRef.close();
      });
  };

  public close = () => {
    this.dialogRef.close();
  };
}
