import { Component, Inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-gas-sample',
  templateUrl: './gas-sample.component.html',
  styleUrls: ['./gas-sample.component.scss'],
})
export class GasSampleComponent {
  public formGroup: FormGroup;

  get errors() {
    const errors: { [key: string]: ValidationErrors | null | boolean } = {};
    const controls = this.formGroup.controls;
    for (const key in controls) {
      if (
        controls[key]?.invalid &&
        controls[key]?.errors &&
        (controls[key]?.dirty || controls[key]?.touched)
      )
        errors[key] = controls[key].errors;
    }
    if (this.formGroup.hasError('sumExceeded')) errors.sumExceeded = true;
    return errors;
  }

  constructor(
    public dialogRef: MatDialogRef<GasSampleComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.formGroup = new FormGroup(
      {
        c2: new FormControl(),
        c3: new FormControl(),
        c4: new FormControl(),
        c5: new FormControl(),
        co2: new FormControl(),
        ch4: new FormControl(),
        hhvBtu: new FormControl(),
        gasSampleBasis: new FormControl(),
        hhvBasis: new FormControl(),
        name: new FormControl(),
        sampleDate: new FormControl(new Date()),
        effectiveDate: new FormControl(new Date()),
      },
      {
        validators: this.#sumValidator(
          ['c2', 'c3', 'c4', 'c5', 'ch4', 'co2'],
          1,
        ),
      },
    );

    if (data) {
      if (!data.isNewSample) {
        data.gasSample.sampleDate = new Date(data.gasSample.sampleDate);
        data.gasSample.effectiveDate = new Date(data.gasSample.effectiveDate);
      }

      this.formGroup.patchValue(data.gasSample);
    }
  }

  public save() {
    if (!this.formGroup.valid) throw 'Please fill in required fields.';
    const gasSample = this.formGroup.getRawValue();
    gasSample.hhv = (gasSample.hhvBtu || 0) / 1000000;
    this.dialogRef.close({
      gasSample,
      isNewSample: this.data.isNewSample,
    });
  }

  #sumValidator(fields: string[], maxSum: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const formGroup = control as any;
      const sum = fields.reduce(
        (acc, field) => acc + (parseFloat(formGroup.get(field)?.value) || 0),
        0,
      );
      return sum > maxSum ? { sumExceeded: { value: sum, max: maxSum } } : null;
    };
  }
}
