import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { Facility } from '@iconic-air-monorepo/models';
import { Observable, from, lastValueFrom, map, of, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import { StaticDataService } from '../../services/static-data/static.data.service';

@Injectable({
  providedIn: 'root',
})
export class FacilitiesDatabaseService {
  get programTypeConfigs() {
    return this._staticDataService.programTypeConfigs;
  }

  constructor(
    private _perms: PermissionsWebGuard,
    private _afs: AngularFirestore,
    private _http: HttpClient,
    private _staticDataService: StaticDataService,
  ) {}

  deactivateFacility(id: string) {
    return from(
      this._afs
        .collection('customers')
        .doc(this._perms.activeCustomerId)
        .collection('facilities')
        .doc(id)
        .update({
          active: false,
          inactivatedDate: Date.now(),
        }),
    );
  }

  activateFacility(id: string) {
    return from(
      this._afs
        .collection('customers')
        .doc(this._perms.activeCustomerId)
        .collection('facilities')
        .doc(id)
        .update({
          active: true,
          activatedDate: Date.now(),
        }),
    );
  }

  getFacilitiesByReportingFacility(
    reportingFacilityId,
  ): Observable<DocumentData[]> {
    return this._afs
      .collection('customers')
      .doc(this._perms.activeCustomerId)
      .collection('facilities', (ref) =>
        ref.where('reportingFacilityId', '==', reportingFacilityId),
      )
      .valueChanges();
  }

  getFacilities(
    isLDAR?: boolean,
    includeInactive?: boolean,
  ): Observable<Facility[]> {
    let route = '/api/facilities';
    if (isLDAR) {
      if (route.indexOf('?') === -1) {
        route += '?';
      }
      route += 'ldar=true';
    }

    if (includeInactive) {
      if (route.indexOf('?') === -1) {
        route += '?';
      } else {
        route += '&';
      }
      route += 'includeInactive=true';
    }
    return this._http.get(route) as Observable<Facility[]>;
  }

  getFacilitiesByKey(
    keys: { includes: string[]; excludes?: string[] },
    isLDAR?: boolean,
    includeInactive?: boolean,
  ): Observable<any> {
    let route = '/api/facilities';
    if (isLDAR) {
      if (route.indexOf('?') === -1) {
        route += '?';
      }
      route += 'ldar=true';
    }

    if (includeInactive) {
      if (route.indexOf('?') === -1) {
        route += '?';
      } else {
        route += '&';
      }
      route += 'includeInactive=true';
    }
    return this._http.post(route, keys);
  }

  async getFacility(id: string) {
    const facility = await lastValueFrom(
      this._afs
        .collection('customers')
        .doc(this._perms.activeCustomerId)
        .collection('facilities')
        .doc(id)
        .get(),
    );
    return { ...facility.data(), id: facility.id } as Facility;
  }

  // gets all facilites that exist in a customer instance for that company.
  // convert to valueChanges but ensure you update create facility
  getCompanyFacilities(customerId?: string) {
    return this._afs
      .collection('customers')
      .doc(customerId || this._perms.activeCustomerId)
      .collection('facilities')
      .valueChanges()
      .pipe(take(1));
  }

  async editFacility(
    model,
    existing: boolean,
    existingFacilityInfo?: Facility,
    bulkUpload?: boolean,
  ) {
    const facility: Facility = {
      companyId: this._perms.activeCustomerId,
      facilityName: model.facilityName,
      basin: model.basin,
      basinNumber: model.basinNumber,
      facilityType: model.facilityType?.toLowerCase(),
      reportingFacilityId: model.reportingFacilityId,
      reportingFacilityName: model.reportingFacilityName,
      address: model.address,
      isLDAR: model.isLDAR,
      facilityId: model.facilityId,
      active:
        model.active === true || model.active === false ? model.active : true,
    };

    const optionalKeys = [
      'county',
      'pointOfContact',
      'pointOfContactEmail',
      'pointOfContactPhone',
      'comments',
      'longitude',
      'latitude',
      'permitType',
      'programTypes',
      'businessArea',
      'softwareId',
      'locationSubType',
      'surveyType',
      'formationType',
      'ownershipPercentage',
      'previousOwner',
    ];
    optionalKeys.forEach((key) => {
      if (model[key]) facility[key] = model[key];
      if (key === 'businessArea') {
        facility[key] = model[key]?.toString() || '';
      } else {
        if (model[key]) facility[key] = model[key];
      }
    });
    if (facility.isLDAR) {
      if (model.avoProgramTypes || model.ogiProgramTypes) {
        const filterKeys = [
          'surveyType',
          'locationType',
          'locationSubType',
          'state',
        ];

        const configs = this.programTypeConfigs.filter((config) => {
          return filterKeys.every((key) => {
            if (config[key] && config[key] !== '') {
              if (Array.isArray(facility[key])) {
                const loweredFacilityValues = facility[key].map((value) =>
                  value.toLowerCase(),
                );
                const lowercasedConfigValue = config[key].toLowerCase();
                if (!loweredFacilityValues.includes(lowercasedConfigValue)) {
                  return false;
                }
              } else {
                if (key === 'state') {
                  if (facility.address.state !== config[key]) {
                    return false;
                  }
                } else if (key === 'locationType') {
                  if (facility.facilityType !== config[key]) {
                    return false;
                  }
                } else {
                  if (facility[key] !== config[key]) {
                    return false;
                  }
                }
              }
            }
            return true;
          });
        });

        let avoConfig: any[] = [];
        if (model.avoProgramTypes) {
          avoConfig = configs.filter(
            (config) =>
              config.surveyType === 'avo' &&
              model.avoProgramTypes
                .map((programType) => programType.toLowerCase())
                .includes(config.programType),
          );
        }

        let ogiConfig: any[] = [];
        if (model.ogiProgramTypes) {
          ogiConfig = configs.filter(
            (config) =>
              config.surveyType === 'ogi' &&
              model.ogiProgramTypes
                .map((programType) => programType.toLowerCase())
                .includes(config.programType),
          );
        }

        let windows: any = {};

        if (existing && existingFacilityInfo?.locationSurveyWindows)
          windows = JSON.parse(
            JSON.stringify(existingFacilityInfo?.locationSurveyWindows),
          );

        if (facility.surveyType.includes('ogi')) {
          if (!existingFacilityInfo?.locationSurveyWindows?.ogi) {
            Object.assign(windows, {
              ogi: {
                nextSurveyWindowStart: '',
                nextSurveyWindowEnd: '',
                lastSurveyDate: '',
                frequency: '',
                firstAttemptDue: '',
                finalAttemptDue: '',
                finalAttemptStartOn: '',
                dorDefaultDueDate: '',
                resurveyRequired: false,
                overrideFrequency: false,
                programTypes: [],
                resurveyDueDate: '',
              },
            });
          }
          const strictestOgiConfig = ogiConfig.sort((a, b) => {
            return a.frequencyCode - b.frequencyCode;
          })[0];

          if (model.ogiOverrideFrequency) {
            windows.ogi.frequency = model.ogiFrequency;
          } else if (model.overrideOgiFrequency) {
            windows.ogi.frequency = model.overrideOgiFrequency;
          } else if (strictestOgiConfig) {
            windows.ogi.frequency = strictestOgiConfig?.surveyFrequency;
          } else {
            windows.ogi.frequency = '';
          }

          windows.ogi.firstAttemptDue =
            strictestOgiConfig?.firstAttemptDue || '';
          windows.ogi.finalAttemptDue =
            strictestOgiConfig?.finalAttemptDue || '';
          windows.ogi.dorDefaultDueDate =
            strictestOgiConfig?.dorDefaultDueDate || '';
          windows.ogi.resurveyRequired =
            strictestOgiConfig?.resurveyRequired || true;
          windows.ogi.resurveyDueDate =
            strictestOgiConfig?.resurveyDueDate || '';
          windows.ogi.programTypes =
            model?.ogiProgramTypes.map((type) => type?.toLowerCase()) || [];

          windows.ogi.overrideFrequency = model.ogiOverrideFrequency
            ? true
            : model.overrideOgiFrequency && model.overrideOgiFrequency !== ''
            ? true
            : false;

          windows.ogi.lastSurveyDate =
            model.lastOgiSurveyDate !== null && model.lastOgiSurveyDate !== ''
              ? moment(model.lastOgiSurveyDate).valueOf()
              : '';

          windows.ogi.configID = strictestOgiConfig?.id || '';
          windows.ogi.finalAttemptStartOn =
            strictestOgiConfig?.finalAttemptStartOn || '';
          windows.ogi.resurveyMethods =
            strictestOgiConfig?.resurveyMethods || [];
        }

        if (facility.surveyType.includes('avo')) {
          if (!existingFacilityInfo?.locationSurveyWindows?.avo) {
            Object.assign(windows, {
              avo: {
                nextSurveyWindowStart: '',
                nextSurveyWindowEnd: '',
                lastSurveyDate: '',
                frequency: '',
                firstAttemptDue: '',
                finalAttemptDue: '',
                finalAttemptStartOn: '',
                dorDefaultDueDate: '',
                resurveyRequired: false,
                overrideFrequency: false,
                programTypes: [],
                resurveyDueDate: '',
              },
            });
          }

          const strictestAvoConfig = avoConfig.sort((a, b) => {
            return a.frequencyCode - b.frequencyCode;
          })[0];

          if (model.avoOverrideFrequency) {
            windows.avo.frequency = model.avoFrequency;
          } else if (model.overrideAvoFrequency) {
            windows.avo.frequency = model.overrideAvoFrequency;
          } else if (strictestAvoConfig) {
            windows.avo.frequency = strictestAvoConfig?.surveyFrequency;
          } else {
            windows.avo.frequency = '';
          }

          windows.avo.overrideFrequency = model.avoOverrideFrequency
            ? true
            : model.overrideAvoFrequency && model.overrideAvoFrequency !== ''
            ? true
            : false;

          windows.avo.firstAttemptDue =
            strictestAvoConfig?.firstAttemptDue || '';
          windows.avo.finalAttemptDue =
            strictestAvoConfig?.finalAttemptDue || '';
          windows.avo.dorDefaultDueDate =
            strictestAvoConfig?.dorDefaultDueDate || '';
          windows.avo.resurveyRequired =
            strictestAvoConfig?.resurveyRequired || true;
          windows.avo.resurveyDueDate =
            strictestAvoConfig?.resurveyDueDate || '';
          windows.avo.programTypes =
            model?.avoProgramTypes.map((type) => type?.toLowerCase()) || [];

          windows.avo.lastSurveyDate =
            model.lastAvoSurveyDate !== null && model.lastAvoSurveyDate !== ''
              ? moment(model.lastAvoSurveyDate).valueOf()
              : '';

          windows.avo.configID = strictestAvoConfig?.id;
          windows.avo.finalAttemptStartOn =
            strictestAvoConfig?.finalAttemptStartOn || '';
          windows.avo.resurveyMethods =
            strictestAvoConfig?.resurveyMethods || [];
        }

        facility.locationSurveyWindows = windows;
      }

      if (model.permitType) {
        facility.permitType = model.permitType;
      }
    }

    if (bulkUpload) {
      return facility;
    } else if (existing) {
      this._afs
        .collection('customers')
        .doc(this._perms.activeCustomerId)
        .collection('facilities')
        .doc(facility.facilityId)
        .update(facility);
    } else {
      this._afs
        .collection('customers')
        .doc(this._perms.activeCustomerId)
        .collection('facilities')
        .doc(facility.facilityId)
        .set(facility);
    }

    return { companyId: this._perms.activeCustomerId };
  }

  async editFacilityContacts(model) {
    const facility = {
      facilityId: model.facilityId,
      pointOfContact: model.pointOfContact,
      pointOfContactEmail: model.pointOfContactEmail,
      pointOfContactPhone: model.pointOfContactPhone,
    };

    this._afs
      .collection('customers')
      .doc(this._perms.activeCustomerId)
      .collection('facilities')
      .doc(facility.facilityId)
      .update(facility);

    return { companyId: this._perms.activeCustomerId };
  }
}
