import { Injectable } from '@angular/core';
import { ProgressStatusDialogComponent } from '../../components/progress-status-dialog/progress-status-dialog.component';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { ProgressStatusDialogService } from '../progress-status-dialog/progress-status-dialog.service';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { lastValueFrom } from 'rxjs';
import { Upload } from '@iconic-air/models';
import { UploadsDatabaseService } from '../../services-database/uploads/uploads-database.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Buffer } from 'buffer';
import { HttpClient } from '@angular/common/http';
import { IdGeneratorService } from '../../services-database/id/id-generator.service';
import { CustomerDataService } from '../customer-data/customer-data.service';

@Injectable({
  providedIn: 'root',
})
export class SaveService {
  constructor(
    private _afs: AngularFirestore,
    private _progressStatusDialog: ProgressStatusDialogService,
    private _perms: PermissionsWebGuard,
    private _uploadDatabase: UploadsDatabaseService,
    private _snackBar: MatSnackBar,
    private _http: HttpClient,
    private _generateId: IdGeneratorService,
    private _customerData: CustomerDataService,
  ) {}

  #currentElementSize(element) {
    // Size in bytes
    return Buffer.byteLength(JSON.stringify(element), 'utf8') / (1024 * 1024);
  }

  #createBatches(
    records: any[],
    ref: AngularFirestoreCollection<any> | AngularFirestoreDocument<any>,
    totalRecords: number,
    progressDialog: ProgressStatusDialogComponent,
    messageStart: string,
    startPercentage: number,
    percentageMultiplier: number,
    batchSize: number,
    collectionName?: string,
    url?: string,
    fileName?: string,
    operation?: 'set' | 'update',
    updateIds?: string[],
  ) {
    let addedRecords = 0;
    const updatePercentage = () => {
      addedRecords++;
      if (addedRecords % 100 === 0)
        progressDialog.changeMessageProgress(
          messageStart + ' (' + addedRecords + ' of ' + totalRecords + ')',
          startPercentage +
            percentageMultiplier * (addedRecords / totalRecords),
        );
    };
    const batches = [this._afs.firestore.batch()];
    const batchCounts: number[] = [0];
    const batchRecordRefs: { ref: any; record: any }[][] = [[]];
    let currentBatchIndex = 0;
    let currentBatchSize = 0;
    let currentBatchCount = 0;

    const addRecord = (ref, record) => {
      // add url and updated information
      if (url) record.url = url;
      if (fileName) record.fileName = fileName;
      if (!record.createdAt && operation !== 'update')
        record.createdAt = Date.now();
      record.updatedAt = Date.now();
      record.lastUpdatedUser = this._perms.userData.uid;
      record.user = {
        uid: this._perms.userData.uid,
        email: this._perms.userData.email,
      };
      currentBatchSize += this.#currentElementSize(record);
      // allow decreasing the size of the batch incase it is failing
      if (currentBatchSize > batchSize) {
        batchCounts.push(0);
        batchRecordRefs.push([]);
        currentBatchCount = 0;
        currentBatchIndex++;
        batches.push(this._afs.firestore.batch());
        currentBatchSize = this.#currentElementSize(record);
      }
      currentBatchCount++;
      batchRecordRefs[currentBatchIndex].push({ ref, record });
      batchCounts[currentBatchIndex]++;
      if (operation === 'update')
        batches[currentBatchIndex].update(ref, record);
      else batches[currentBatchIndex].set(ref, record);
      updatePercentage();
    };

    let recordCount = 0;
    records.forEach((rawRecord, index) => {
      recordCount++;
      let rawRef;
      if (collectionName)
        rawRef = (ref as AngularFirestoreDocument<any>)
          .collection(rawRecord[collectionName])
          .doc(updateIds?.[index] || rawRecord.id).ref;
      else
        rawRef = (ref as AngularFirestoreCollection<any>).doc(
          updateIds?.[index] || rawRecord.id,
        ).ref;
      addRecord(rawRef, rawRecord);
    });
    return {
      batches,
      batchRecordRefs,
      recordCount,
      batchCounts,
    };
  }

  /**
   * Saves the API by performing the following steps:
   * 1. Prepares the file and data for upload.
   * 2. Uploads the file to the specified folder.
   * 3. Saves the records to staging.
   * 4. Moves the records to the final location.
   *
   * @param {File} file - The file to be saved.
   * @param {string} folder - The folder where the file will be saved.
   * @param {string} type - The type of the file.
   * @param {string} fileType - The file type.
   * @param {string} reportingList - The reporting list.
   * @param {any[]} records - The records associated with the file.
   * @param {number} editedCount - The number of edited records.
   * @param {string} endpoint - The API endpoint.
   * @return {Promise<void>} A promise that resolves when the API has been saved.
   */
  async saveApi(
    file: File,
    folder: string,
    type: string,
    fileType: string,
    reportingList: string,
    records: any[],
    editedCount: number,
    endpoint: string,
  ) {
    const progressDialog = this._progressStatusDialog.open(
      'saving',
      '(1/4) Prepping file and data for upload',
    );
    const transactionId = this._generateId.createId();
    // step 1 - create file to upload
    const fileName = Date.now() + '_' + file.name;
    progressDialog.changeMessageProgress('(2/4) Uploading file', 10);
    try {
      const url = await this.uploadFile(file, folder, fileName, true);
      progressDialog.changeProgress(15);
      const uploadId = this._afs.createId();

      const uploadDoc: Upload = {
        fileUrl: url,
        id: uploadId,
        fileType,
        createdUser: this._perms.userData.email,
        createdUserId: this._perms.userData.uid,
        customerName: this._customerData.customerRecord?.name,
        createdDate: Date.now(),
        createdDateString: new Date(Date.now()).toISOString(),
        fileName: file.name,
        reportingList,
      };

      const upload = await this._uploadDatabase.createUpload(uploadDoc);

      progressDialog.changeProgress(20);
      if (upload.error) {
        this._progressStatusDialog.close(progressDialog);
        throw upload.error;
      }

      progressDialog.changeMessageProgress(
        '(3/4) Saving records to staging (0 of ' + records.length + ')',
        25,
      );

      let recordsToSave: any[] = [],
        count = 0,
        currentSize = 0;
      for (const record of records) {
        const recordSize = this.#checkRecordSize(record);
        if (currentSize + recordSize > 10) {
          await lastValueFrom(
            this._http.put(endpoint, { data: recordsToSave, transactionId }),
          );
          recordsToSave = [];
          currentSize = 0;
          progressDialog.changeMessageProgress(
            '(3/4) Saving records to staging (' +
              count +
              ' of ' +
              records.length +
              ')',
            25 + parseFloat(((count / records.length) * 50).toFixed(0)),
          );
        }
        count++;
        currentSize += recordSize;
        recordsToSave.push(record);
      }
      if (recordsToSave.length) {
        await lastValueFrom(
          this._http.put(endpoint, { data: recordsToSave, transactionId }),
        );
        progressDialog.changeMessageProgress(
          '(3/4) Saving records to staging (' +
            count +
            ' of ' +
            records.length +
            ')',
          25 + parseFloat(((count / records.length) * 50).toFixed(0)),
        );
      }

      progressDialog.changeMessageProgress('(4/4) Moving records', 75);

      await lastValueFrom(this._http.put(endpoint + '/' + transactionId, {}));

      this._snackBar.open(
        'Records have been saved. ' +
          (records.length - editedCount) +
          ' record(s) created. ' +
          editedCount +
          ' record(s) been updated. ',
        'OK',
        {
          verticalPosition: 'top',
          duration: 10000,
        },
      );
    } finally {
      this._progressStatusDialog.close(progressDialog);
    }
  }

  #checkRecordSize(record: any): number {
    // Convert to JSON string and calculate the size
    const jsonData = JSON.stringify(record);
    const sizeInBytes = new Blob([jsonData]).size;
    const sizeInMB = sizeInBytes / (1024 * 1024);
    // Return true if the size is within the limit
    return sizeInMB;
  }

  async uploadFile(
    file: File | Uint8Array | Blob,
    folder: string,
    fileName: string,
    customerSpecific: boolean,
  ) {
    let url = '';
    const chunkSize = 9 * 1024 * 1024; // 9MB chunks
    let totalChunks, fileSize, mimeType;

    if (file instanceof File || file instanceof Blob) {
      fileSize = file.size;
      mimeType = file.type;
    } else if (file instanceof Uint8Array) {
      fileSize = file.length;
      // assume it is an excel sheet
      mimeType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      file = new Blob([file], { type: mimeType }); // Convert to Blob
    } else {
      throw new Error('Unsupported file type');
    }

    totalChunks = Math.ceil(fileSize / chunkSize);
    let chunkNumber = 1;

    for (let start = 0; start < fileSize; start += chunkSize) {
      const chunk = file.slice(start, start + chunkSize);
      const formData = new FormData();
      formData.append('file', chunk, `${fileName}.part${chunkNumber}`);
      formData.append('fileName', fileName);
      formData.append('folder', folder);
      formData.append('customerSpecific', customerSpecific ? 'true' : 'false');
      formData.append('chunkNumber', chunkNumber.toString());
      formData.append('totalChunks', totalChunks.toString());

      try {
        url = (await lastValueFrom(
          this._http.put(`/api/upload-file`, formData),
        )) as string;
      } catch (error) {
        console.error('Chunk upload failed:', error);
        throw error;
      }

      chunkNumber++;
    }

    return url;
  }

  /**
   * Saves the file and associated data to the specified folder and to the database
   *
   * @param {File} file - The file to be saved.
   * @param {string} folder - The folder where the file will be saved.
   * @param {string} type - The type of the file.
   * @param {string} fileType - The file type.
   * @param {string} reportingList - The reporting list.
   * @param {any[]} records - The records associated with the file.
   * @param {number} editedCount - The number of edited records.
   * @param {AngularFirestoreCollection<any> | AngularFirestoreDocument<any>} ref - The reference to the AngularFirestoreCollection or AngularFirestoreDocument.
   * @param {number} batchSize - The size of each batch.
   * @param {string} [collectionName] - The name of the collection.
   * @param {'set' | 'update'} operation - The operation to be performed.
   * @param {string[]} [updateIds] - The ids of the records to be updated.
   */
  async save(
    file: File,
    folder: string,
    type: string,
    fileType: string,
    reportingList: string,
    records: any[],
    editedCount: number,
    ref: AngularFirestoreCollection<any> | AngularFirestoreDocument<any>,
    batchSize: number,
    collectionName?: string,
    operation: 'set' | 'update' = 'set',
    updateIds?: string[],
  ) {
    const progressDialog = this._progressStatusDialog.open(
      'saving',
      '(1/4) Prepping file and data for upload',
    );
    // step 1 - create file to upload
    const fileName = Date.now() + '_' + file.name;
    progressDialog.changeMessageProgress('(2/4) Uploading file', 10);

    try {
      const url = await this.uploadFile(file, folder, fileName, true);

      progressDialog.changeProgress(15);

      const uploadDoc: Upload = {
        fileUrl: url,
        id: this._generateId.createId(),
        fileType,
        type,
        createdUser: this._perms.userData.email,
        createdUserId: this._perms.userData.uid,
        customerName: this._customerData.customerRecord?.name,
        createdAt: Date.now(),
        createdDate: Date.now(),
        createdDateString: new Date(Date.now()).toISOString(),
        fileName: file.name,
        reportingList,
      };

      const upload = await this._uploadDatabase.createUpload(uploadDoc);
      progressDialog.changeProgress(20);
      if (upload.error) {
        this._progressStatusDialog.close(progressDialog);
        throw upload.error;
      }

      progressDialog.changeMessageProgress(
        '(3/4) Preping values to be saved',
        25,
      );

      const { batches, batchRecordRefs, recordCount, batchCounts } =
        await this.#createBatches(
          records,
          ref,
          records.length,
          progressDialog,
          '(3/4) Preping values to be saved ',
          25,
          5,
          batchSize,
          collectionName,
          url,
          fileName,
          operation,
          updateIds,
        );

      progressDialog.changeMessageProgress(
        '(4/4) Creating records (0 of ' + records.length + ')',
        30,
      );
      let recordsSaved = 0;
      for (
        let currentIndex = 0;
        currentIndex < batches.length;
        currentIndex++
      ) {
        const currentBatch = batches[currentIndex];
        const currentBatchRecordRefs = batchRecordRefs[currentIndex];
        const currentBatchCounts = batchCounts[currentIndex];
        await this.#commitBatch(
          currentBatch,
          currentBatchRecordRefs,
          operation,
        );
        recordsSaved += currentBatchCounts;
        if (recordsSaved > recordCount) recordsSaved = recordCount;

        progressDialog.changeMessageProgress(
          '(4/4) Creating records (' +
            recordsSaved +
            ' of ' +
            recordCount +
            ')',
          30 + parseFloat(((currentIndex / batches.length) * 70).toFixed(0)),
        );
      }
      this._progressStatusDialog.close(progressDialog);
      this._snackBar.open(
        'Records have been saved. ' +
          (records.length - editedCount) +
          ' record(s) created. ' +
          editedCount +
          ' record(s) been updated. ',
        'OK',
        {
          verticalPosition: 'top',
          duration: 10000,
        },
      );
    } finally {
      this._progressStatusDialog.close(progressDialog);
    }
  }

  #createBatchesFromRecordRefs(
    recordRefs: { ref: any; record: any }[],
    operation: 'set' | 'update',
  ) {
    const batch = this._afs.firestore.batch();
    recordRefs?.forEach((recordRef) => {
      if (operation === 'set') batch.set(recordRef.ref, recordRef.record);
      else if (operation === 'update')
        batch.update(recordRef.ref, recordRef.record);
    });
    return batch;
  }

  /**
   * Commits a batch of records to the database. If the batch fails it will split the records in half and recursively keep splitting until it succeeds
   *
   * @param {type} batch - the batch to commit
   * @param {type} batchRecordRefs - an array of references to the records in the batch
   * @param {type} operation - the operation to perform on the batch
   * @return {type} Promise<void> - a promise that resolves when the batch is committed
   */
  async #commitBatch(
    batch,
    batchRecordRefs: { ref: any; record: any }[],
    operation: 'set' | 'update',
  ) {
    // we need to try and commit, if we fail we need to split the records in half and commit them individually
    try {
      await batch.commit();
    } catch (e) {
      let message = 'Batches failed.';
      if (typeof e?.message === 'string')
        message += ' With error: ' + e?.message;
      else if (typeof e === 'string') message += ' With error: ' + e;
      else message += ' With error: ' + JSON.stringify(e);
      if (message?.toLowerCase()?.includes('transaction too big')) {
        // split the batch records in half
        const half = Math.ceil(batchRecordRefs.length / 2);
        const firstHalfRecordRefs = batchRecordRefs.slice(0, half);
        const secondHalfRecordRefs = batchRecordRefs.slice(half);
        const firstHalfBatch = this.#createBatchesFromRecordRefs(
          firstHalfRecordRefs,
          operation,
        );
        const secondHalfBatch = this.#createBatchesFromRecordRefs(
          secondHalfRecordRefs,
          operation,
        );
        await this.#commitBatch(firstHalfBatch, firstHalfRecordRefs, operation);
        await this.#commitBatch(
          secondHalfBatch,
          secondHalfRecordRefs,
          operation,
        );
      } else throw message;
    }
  }
}
