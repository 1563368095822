import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

export enum FileCategory {
  GEOTIFF = 'geotiff',
  PID = 'pid',
  WALKING_PATH = 'walkingPath',
  PERMIT = 'permit',
  AIR_PERMIT = 'airPermit',
  GHG_INVENTORY = 'ghgInventory',
}

export enum FileType {
  PDF = 'pdf',
  IMAGE = 'image',
  GEOTIFF = 'geotiff',
  WEB = 'web',
}

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {
  constructor() {}
  @Input('title') public title: string;
  @Input('type') public type: string;
  @Input('url') public url: string;
  @Input('fileCategory') public fileCategory: FileCategory;
  @Output('download') public download = new EventEmitter<string>();
  @Output('upload') public upload = new EventEmitter<string>();
  @Output('route') public route = new EventEmitter<string>();

  ngOnInit() {}

  downloadFile() {
    this.download.emit(this.url);
  }

  uploadFile() {
    if (this.type === FileType.WEB) {
      this.route.emit(this.url);
    } else {
      this.upload.emit(this.title);
    }
  }
}
