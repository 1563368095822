// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  environmentName: 'develop',
  staging: false,
  production: false,
  develop: true,
  project_url:
    'https://firestore.googleapis.com/v1beta1/projects/iconic-air-dev-eb49d/databases/(default)/documents',
  storageBucket: 'gs://iconic-air-dev-eb49d.appspot.com',
  cloudFunctionUrl: 'https://us-east4-iconic-air-dev-eb49d.cloudfunctions.net',
  vimeoAccessToken: 'abd4d2ff90eaf84b55ea488f6d5d4f3f',
  firebaseConfig: {
    apiKey: 'AIzaSyBCXnInq759MPFbnzsc83kf7cgsTfeHvmc',
    authDomain: 'dev.iconicair.io',
    databaseURL: 'https://iconic-air-dev-eb49d-default-rtdb.firebaseio.com',
    projectId: 'iconic-air-dev-eb49d',
    storageBucket: 'iconic-air-dev-eb49d.appspot.com',
    messagingSenderId: '974005097322',
    appId: '1:974005097322:web:80a76fb8dc675d2a4c4b13',
    measurementId: 'G-YHHSQJFDRC',
  },
  awsS3Config: {
    accessKeyId: 'AKIAUGI7YWX5YKQDRDFW',
    secretAccessKey: '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',
    region: 'us-east-2',
  },
};

// not used as an api key anywhere, added to show current env banner to users
export const firebaseProjectAlias = {
  env: 'dev',
  projectName: 'Iconic Air Development Instance.',
  projectDescription:
    'This instance is used to test new features being built by the Iconic Engineering team',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
