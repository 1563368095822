import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  SheetValidationError,
  Survey,
  Leak,
  Repair,
  Facility,
} from '@iconic-air-monorepo/models';
import { LocationService } from '../../pages/locations/location-details/services/location.service';
import { ValidationService } from '../../services/validation/validation.service';
import * as XLSX from 'xlsx';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-data-validation',
  templateUrl: './data-validation.component.html',
})
export class DataValidationComponent implements OnInit {
  wb: XLSX.WorkBook;
  fileLoading = true;
  errorLog: SheetValidationError[] = [];
  templateName: string;
  sheetNames: string[] = [];
  surveys: Survey[] = [];
  leaks: Leak[] = [];
  repairs: Repair[] = [];
  facilities: Facility[] = [];
  #ignoreDataGrab: boolean;

  constructor(
    private dialogRef: MatDialogRef<DataValidationComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _locationService: LocationService,
    private _validationService: ValidationService,
  ) {
    this.wb = data.wb;
    this.templateName = data.templateName;
    this.sheetNames = data.sheetNames;
    this.facilities = data.facilities;
    this.#ignoreDataGrab = !!data.ignoreDataGrab;
  }

  ngOnInit(): void {
    this.processData();
  }

  async processData() {
    if (!this.#ignoreDataGrab) {
      const data = await firstValueFrom(
        this._locationService.getLeaksRepairsAndSurveys(),
      );
      if (data) {
        this.leaks = data.leaks as Leak[];
        this.surveys = data.surveys as Survey[];
        this.repairs = data.repairs as Repair[];
      }
    }
    // Validate Template
    const newErrors = await this._validationService.validateTemplate(
      this.templateName,
      this.wb,
      this.sheetNames,
    );
    if (newErrors !== null && newErrors.length > 0) {
      newErrors.forEach((i) => this.errorLog.push(i));
    }

    // Validate Data
    const newDataErrors = await this._validationService.validateData(
      this.templateName,
      this.wb,
      this.sheetNames,
      this.surveys,
      this.leaks,
      this.repairs,
      this.facilities,
    );

    if (newDataErrors !== null && newDataErrors.length > 0) {
      newDataErrors.forEach((i) => this.errorLog.push(i));
    }

    this.dialogRef.close(this.errorLog);
  }

  closeModal() {
    this.dialogRef.close(undefined);
  }
}
