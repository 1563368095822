import { Injectable } from '@angular/core';

import {
  RawEmissions,
  ReportingFacility,
  Facility,
  Reading,
  Equipment,
  RawEmissionsMetrics,
} from '@iconic-air/models';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { TitleCasePipe } from '@angular/common';
import { IdGeneratorService } from '../../services-database/id/id-generator.service';
import { isEqual, uniq } from 'lodash';
import { StaticDataService } from '../static-data/static.data.service';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RawEmissionService {
  constructor(
    private _perms: PermissionsWebGuard,
    private _titleCase: TitleCasePipe,
    private _idGenerator: IdGeneratorService,
    private _staticData: StaticDataService,
    private titleCase: TitleCasePipe,
    private _http: HttpClient,
  ) {}

  async getRawEmissions(filters?: { [key: string]: any }) {
    return await lastValueFrom(
      this._http.get<RawEmissions[]>('/api/raw-emissions', {
        params: filters,
      }),
    );
  }

  async getRawEmissionYears() {
    return await lastValueFrom(
      this._http.get<string[]>('/api/raw-emissions/years'),
    );
  }

  async saveRawEmissions(rawEmissions: RawEmissions[]) {
    return await lastValueFrom(
      this._http.post('/api/raw-emissions', { data: rawEmissions }),
    );
  }

  createRawEmission(
    emissionSource: string,
    reportingFacility: ReportingFacility,
    facility: Facility,
    reading: Reading,
    reportingYear: string,
    equipment: Equipment,
    emissionsMetrics: { [key: string]: RawEmissionsMetrics },
    frameworkVersions: string[],
    reportingPeriod: string,
    methodologies?: string[],
    id?: string,
    ignoreHighLevelSourceType?: boolean,
  ): RawEmissions {
    const source = this._staticData.emissionSources.find(
      (ss) => ss.key === emissionSource,
    );
    const rawEmissionRecord: RawEmissions = {
      calcInfo: {},
      basinFullName: reportingFacility?.reportingFacilityBasinFullName,
      basinId: reportingFacility?.reportingFacilityBasinId,
      basinName: reportingFacility?.reportingFacilityBasinName,
      basinNumber: reportingFacility?.reportingFacilityBasinNumber,
      // indseg
      industrySegmentId: reportingFacility?.reportingFacilityIndustrySegmentId,
      industrySegmentLongName:
        reportingFacility?.reportingFacilityIndustrySegmentLongName,
      industrySegmentShortName:
        reportingFacility?.reportingFacilityIndustrySegmentShortName,
      reportingFacilityId: reportingFacility?.id,
      reportingFacilityName: reportingFacility?.reportingFacilityName,
      createdAt: Date.now(),
      createdUserEmail: this._perms.userData?.email,
      createdUserUid: this._perms.userData?.uid,
      lastUpdatedUser: this._perms.userData?.uid,
      date: Date.now(),
      emissionsMetrics,
      facilityId: facility.facilityId,
      facilityName: facility.facilityName,
      facilityDisplayName: facility.displayName
        ? facility.displayName
        : this._titleCase.transform(facility.facilityName),
      equipmentType: source.label,
      equipmentTypeKey: emissionSource,
      highLevel: source.highLevel,
      id: id ? id : this._idGenerator.createId(),
      reportingPeriod: reportingPeriod?.toString(),
      year: parseFloat(reportingYear),
      sourceType: reading?.sourceType
        ? reading.sourceType
        : source?.sourceType
        ? source.sourceType
        : source.highLevel,
    };
    if (!reading?.sourceType && ignoreHighLevelSourceType)
      delete rawEmissionRecord.sourceType;

    if (equipment) {
      rawEmissionRecord.equipment = equipment;
      rawEmissionRecord.equipmentId = equipment.id;
      rawEmissionRecord.calcInfo.equipmentId = equipment.id;
      rawEmissionRecord.calcInfo.equipmentName = equipment.equipmentName || '';
    }

    if (reading) {
      Object.keys(reading).forEach((readingField) => {
        if (
          reading[readingField] !== undefined &&
          reading[readingField] !== null &&
          reading[readingField]?.toString() !== '' &&
          ![
            'facilityId',
            'facilityDisplayName',
            'reportingPeriod',
            'rowNumber',
            'sheetIndex',
            'sheetName',
          ].includes(readingField)
        ) {
          rawEmissionRecord.calcInfo[readingField] = reading[readingField];
        }
      });
    }

    // legacy fields
    if (methodologies?.length)
      rawEmissionRecord.calcInfo.methodologies = methodologies;

    // new fields if there are non legacy things
    if (
      !methodologies?.length ||
      methodologies.length < frameworkVersions.length
    )
      rawEmissionRecord.calcInfo.frameworkVersions = frameworkVersions;

    return rawEmissionRecord;
  }

  compareEmissionRecords(newValue, oldValue) {
    function getCalcFields(value) {
      if (!value?.calcInfo) return [];
      return Object.keys(value?.calcInfo).sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    }
    function getRegulationValues(value) {
      const returnValue = {};
      Object.keys(value?.emissionsMetrics)?.forEach((key) => {
        const emissions = value.emissionsMetrics[key];
        let keyToStore =
          emissions.regulation ||
          `${emissions.framework} | ${emissions.version}`;
        if (emissions.sourceType) keyToStore += ` | ${emissions.sourceType}`;
        returnValue[keyToStore] = {
          co2: emissions?.co2,
          ch4: emissions?.ch4,
          n20: emissions?.n20,
        };
      });
      return returnValue;
    }
    // check to see if anything changed
    const calcInfoFields = uniq(getCalcFields(newValue).concat(oldValue));
    for (const field of calcInfoFields) {
      if (
        field !== 'equipment' &&
        !isEqual(oldValue?.calcInfo[field], newValue?.calcInfo[field])
      )
        return true;
    }
    const frameworkVersionValues = getRegulationValues(newValue);
    const existingFrameworkVersionValues = getRegulationValues(oldValue);
    const frameworkVersions = uniq(
      Object.keys(frameworkVersionValues).concat(
        Object.keys(existingFrameworkVersionValues),
      ),
    );
    for (const frameworkVersion of frameworkVersions) {
      if (
        !isEqual(
          frameworkVersionValues[frameworkVersion],
          existingFrameworkVersionValues[frameworkVersion],
        )
      )
        return true;
    }
    return false;
  }
}
