import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { AuthenticationWebService } from '../authentication/authentication-web.service';
import { environment } from '../../../environments/environment';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root',
})
export class HttpService implements HttpInterceptor {
  constructor(
    private _auth: AuthenticationWebService,
    private afAuth: AngularFireAuth,
  ) {}

  #lastLoggedIdToken: string | undefined;

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.#refreshToken(req.url).pipe(
      switchMap(() => {
        if (req.url.indexOf('firestore') == -1 && !req.url.startsWith('http')) {
          req = req.clone({ url: environment.cloudFunctionUrl + req.url });
        }

        if (
          this._auth.idToken &&
          req.url.indexOf('vimeo') == -1 &&
          !req.url.startsWith('https://firebasestorage.googleapis.com')
        ) {
          req = req.clone({
            url: req.url,
            setHeaders: {
              idToken: this._auth.idToken ? this._auth.idToken : '',
            },
          });
        } else if (req.url.indexOf('vimeo') == -1) {
          req = req.clone({
            url: req.url,
          });
        }

        return next.handle(req);
      }),
    );
  }

  #refreshToken(url: string): Observable<void> {
    return new Observable<void>((observer) => {
      this.afAuth.currentUser
        .then((user) => {
          user?.getIdTokenResult().then((token) => {
            if (token?.token !== this.#lastLoggedIdToken) {
              this.#lastLoggedIdToken = token?.token;
              console.log(token?.token);
              console.log(token?.expirationTime);
            }
            this._auth.idToken = token?.token;
            observer.next();
            observer.complete();
          });
          if (!user) throw new Error('User not logged in');
        })
        .catch((error) => {
          if (
            url === '/signupUser' ||
            url?.startsWith('/contentManagementSystem')
          ) {
            observer.next();
            observer.complete();
            return;
          }
          throw error;
        });
    });
  }
}
